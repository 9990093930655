import { Component, NgZone, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { KlassaktUserService, KlassaktStorageService, KlassaktApiService} from '../../core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrManager } from 'ng6-toastr-notifications';
import { KlassaktLoaderService } from '../../core/api/dashboard.sevice';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { Response } from 'selenium-webdriver/http';
@Component({
  selector: 'Klassakt-staticPages',
  templateUrl: './staticPages.component.html',
  //styleUrls: ['./staticPages.component.scss']
})
export class KlassaktStaticPagesComponent implements OnInit, OnDestroy {
  private _error = '';
  public static pageName: string;
  private sub: any;
  private static pages: KeyValuePair[] = [];
  public static page: any;
  public static hash: string = '';
  public folderName: string = "pages";
  public static selectedMenuItem : string = "";
  public get $error(): string {
    return this._error;
  }
  private _data = { username: '', password: '' };
  public get $data(): any {
    return this._data;
  }
  public SetAccount(userId: string, Password: string)
  {
    this._data.username = userId;
    this._data.password = Password;
    this.$login();
  }
// InquiryForm: FormGroup;
  //submitted = false;
  public statics = KlassaktStaticPagesComponent;
  //constructor(private formBuilder: FormBuilder) { }
  constructor(
   // private formBuilder: FormBuilder,
    private loaderService: KlassaktLoaderService,
    private _userService: KlassaktUserService,
    private _router: Router,
    private _apiService: KlassaktApiService,
    private _storageService: KlassaktStorageService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    protected toastr: ToastrManager ,
    private zone: NgZone,
    private socialAuthService: AuthService
  ) {
    this._data.username = this._storageService.getItem('username');
    window["publicAngularMethods"] = {component:this};
  }

  public $onclickFacebook() {

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
      console.log(this.user)

      if (userData != undefined || userData != null) {

        this._data.username = userData.email
        this._data.password = userData.id

        this._apiService.login(this._data.username, this._data.password)
          .toPromise().then(
            async () => {
              if (this._userService.isLoggedIn) {
                this._storageService.setItem('username', this._data.username);
                setTimeout(() => { this._router.navigateByUrl('/dashboard') }, 250);
                this.loaderService.hide();
              } else {
                this.loaderService.hide();
                this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
              }
            },
            (error) => {
              this.loaderService.hide();
              this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
            });

      }
      this.loaderService.show();
      this._error = null;

    });
   
  }
  public $onclickSocial() {
    
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
      console.log(this.user)

      if (userData != undefined || userData != null) {

        this._data.username = userData.email
        this._data.password = userData.id

        this._apiService.login(this._data.username, this._data.password)
          .toPromise().then(
            async () => {
              if (this._userService.isLoggedIn) {
                this._storageService.setItem('username', this._data.username);
                setTimeout(() => { this._router.navigateByUrl('/dashboard') }, 250);
                this.loaderService.hide();
              } else {
                this.loaderService.hide();
                this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
              }
            },
            (error) => {
              this.loaderService.hide();
              this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
            });
      }
      this.loaderService.show();
      this._error = null;
    });
   
  }
    user(user: any): any {
        throw new Error("Method not implemented.");
    }
  public $login() {
    //let wordpress;
    this.loaderService.show();
    this._error = null;
    this._apiService.login(this._data.username, this._data.password)
      .toPromise().then(
        async () => {
          if (this._userService.isLoggedIn) {
            this._storageService.setItem('username', this._data.username);
            setTimeout(() => { this._router.navigateByUrl('/dashboard') }, 250);
            this.loaderService.hide();
          } else {

            //if (this._data.wordpress != null) {

            //  redirect on wordpress page.
            //}

            this.loaderService.hide();
            this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
          }
        },
      (error) => {
        this.loaderService.hide();
        this.toastr.errorToastr('Invalid Username or password', 'Error', { timeOut: 25000 , position: 'top-full-width1', showCloseButton: true });
        });
  }

  async ngOnInit() {
    document.getElementById('myModal').style.display = "block";
        this.sub = this.route.params.subscribe(async params => {
          await this.loadPage(params['pageName']);
          if(this.statics.hash != '') {
            window.location.hash = this.statics.hash;
            this.statics.hash = '';
          }
    });
  }
  private async loadPage(pageName) {
    this.statics.pageName = pageName;
    this.statics.pageName = (this.statics.pageName == undefined || this.statics.pageName.trim() == '' || this.statics.pageName.trim() == '/') ? 'home' : this.statics.pageName;
    if (this.statics.pageName.trim() == 'home' || this.statics.pageName.trim() == 'register' || this.statics.pageName.trim() == 'forgotPassword' || this.statics.pageName.trim() == 'resetPassword') {
      this.loaderService.hide();
      return;
    }
    if (!this.statics.pages[this.statics.pageName]) {
      this.loaderService.show();
      var res = await this._apiService.postNonJson('/' + this.statics.pageName + '.html');
      if (res != undefined && res.ok) {
        this.statics.pages[this.statics.pageName] = res.text();
      }
    }
    this.statics.page = this.sanitizer.bypassSecurityTrustHtml(this.statics.pages[this.statics.pageName]);
    this.loaderService.hide();
  }

  colseModal() {

    document.getElementById("myModal").style.display = "none";

  }

  public async parentsubmit(details) {
    this.zone.run(async () => {
      //this.loaderService.show();
      let opt = { timeOut: 15000, position: 'top-full-width1', showCloseButton: true };
      let title = "Contact Us";
      let isParent = this.getFieldValue(details, "IsParent") == "true";
      console.log(this.getFieldValue(details, "IsParent"));
        if (this.getFieldValue(details, "Name") == "") { this.toastr.errorToastr("Enter 'First Name'", title, opt); return; }
        if (this.getFieldValue(details, "LastName") == "") { this.toastr.errorToastr("Enter 'Last Name'", title, opt); return; }
        if (this.getFieldValue(details, "EmailAddress") == "") { this.toastr.errorToastr("Enter 'Email Address'", title, opt); return; }
        if (this.getFieldValue(details, "MobileNo") == "") { this.toastr.errorToastr("Enter 'Mobile Number'", title, opt); return; }
        if (this.getFieldValue(details, "SchoolName") == "") { this.toastr.errorToastr("Enter 'School Name'", title, opt); return; }
      if (this.getFieldValue(details, "SchoolCity") == "") { this.toastr.errorToastr("Enter 'School City'", title, opt); return; }
      if (this.getFieldValue(details, "Message") == "") { this.toastr.errorToastr("Enter 'Message'", title, opt); return; }
      if (isParent && this.getFieldValue(details, "StudentClass") == "") { this.toastr.errorToastr("Enter 'Student Class'", title, opt); return; }
       
      if (isParent && this.getFieldValue(details, "SelectCountry") == "") { this.toastr.errorToastr("Select Country'", title, opt); return; }
     
      let result = await this._apiService.post('api/ContactUs/Contact', details);
 
    if (!result) {
      this.toastr.successToastr('There was some error', 'Error!', opt);
      return false;
      }
      this.toastr.successToastr("Message Sent successfully", "Success", opt);
      
      await this.loadPage('thankyou');
     
    });
   
  }

  public getFieldValue(details,fieldName) : string {
    for (let item of details) {
      if (item.name == fieldName) return item.value;
    }
    return "";
  }
 
  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
    
  }

  public setMenuItemName(menuItemName: string, path: string) {
    this.statics.selectedMenuItem = menuItemName;
    if(path!='')
      this._router.navigateByUrl('/' + this.folderName + '/' + path);
    else
      this._router.navigateByUrl('/');
  }

  public goTo(location: string, menuItemName: string) {
    this.statics.hash = location;
    this.setMenuItemName(menuItemName, '');
    window.location.hash = location;
  }

}

interface KeyValuePair {
  key: string;
  value: string;
}

