import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { KlassaktDashboardComponent } from '../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { from } from 'rxjs';

@Component({
  selector: 'klassakt-orderSummary',
  templateUrl: './orderSummary.component.html'
})
export class KlassaktOrderSummaryComponent extends KlassaktDashboardComponent implements OnInit {
  public downloadInvoiceUrl: any;

  async ngOnInit() {
    await super.ngOnInit();

  }

  public isOrdersSelected: boolean = true;

  protected async onAfterStudentsLoaded() {
    this.account.highlightStudent = false;
    this.account.isheartPink = false;
    this.loadOrderpagedata();
  }

  async loadOrderpagedata() {
    this.account.orders = await this._apiService.post('api/getOrderSummaryDetails', { StudentID: 0, AccountID: this._userService.data.id });

    //for (let i in orders) {
    //  let student = this.getStudent(orders[i].userID);
    //  student.orders.list.push(orders[i]);
    //}
  }

  public async downloadInvoice(oid, onumber) {
   window.open('/api/InvoiceDownload/' + oid + '/' + onumber);
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
