import { Component, OnInit, Injectable, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { KlassaktStaticPagesComponent } from '../staticPages/staticPages.component';
declare var $: any;

@Component({
  selector: 'klassakt-resetPassword',
  templateUrl: './resetPassword.component.html'
})
export class KlassaktResetPasswordComponent implements OnInit, OnDestroy {

  constructor(
    // private formBuilder: FormBuilder,
    @Inject(KlassaktStaticPagesComponent) private staticpage,
    private loaderService: KlassaktLoaderService,
    private _userService: KlassaktUserService,
    private _router: Router,
    private _apiService: KlassaktApiService,
     private route: ActivatedRoute,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager
  
  ) {
  }
  
  public newObject: any = {};
  private sub: any;
  private FID: string;
  private primaryMail: string;
  private userIdNew: any;
  async ngOnInit() {
    this.newObject.IsAgreed = true;
    this.sub = this._route.queryParams.subscribe(async params => {
      this.FID = params['FID'];
      if (this.FID != undefined && this.FID != null && this.FID.trim() != "") {
        let s = await this._apiService.post<any>('api/Register/VerifyPassword/' + this.FID, null);
        this.userIdNew = +s.userId;
        this.primaryMail = s.primaryEmail;
      }
     
    });
  }


  async CheckForValidation() {
    var patt = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9\w~@@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,16})$/;
    if (patt.test(this.newObject.New)) { }
    else {
      this.toastr.errorToastr("Password must be of atleast 8 characters with 1 number, 1 lowercase and 1 uppercase.", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.newObject.New = "";
      return false;
    }
  }
  async ChangePassSubmit() {
    if (this.newObject.IsAgreed == true) {
        if (this.newObject.New != "" && this.newObject.New != null && this.newObject.Conform != "" && this.newObject.Conform != null) {
          if (this.newObject.New == this.newObject.Conform) {
            let s = await this._apiService.post<string>('api/Register/ResetPassword', { newPassword: this.newObject.New, usId: this.userIdNew });
            this.toastr.successToastr('Password changed successfully.', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
            await this.staticpage.SetAccount(this.primaryMail, this.newObject.New);
          }
          else {
            this.toastr.errorToastr('New password and Conform password must be same', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
            this.newObject.New = ""; this.newObject.Conform = "";
          }
        }
     
    } else {
      this.toastr.errorToastr('You need to agree for change password else you can not change password.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    }

  }


  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }

}
