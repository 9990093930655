import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class KlassaktNotFoundComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
