import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { KlassaktDashboardComponent } from '../..';
import { KlassaktProductsComponent } from '../products.component';
import { debounce, retry } from 'rxjs/operators';

@Component({
  selector: 'klassakt-product',
  templateUrl: './productDetails.component.html'
})
export class KlassaktProductDetailsComponent extends KlassaktProductsComponent implements OnInit, OnDestroy {
  private sub: any;
  private productId: any;
  public SelectedProduct: any;

  async ngOnInit() {
    await super.ngOnInit();
    this.sub = this._route.params.subscribe(async params => {
      this.productId = params['productId'];


    });
  }

  protected async onAfterStudentsLoaded() {
    if (!this.productId) {
      this._router.navigateByUrl('/dashboard');
      return;
    }
    if (this.selectedStudent) {
      if (!this.selectedStudent.products || !this.selectedStudent.products.list) {
        await this.onVisible();
      }
      if (this.selectedStudent.products && this.selectedStudent.products.list) {
        this.SelectedProduct = this.selectedStudent.products.list.find(a => a.productId == this.productId);
        
      }
    }
  }


  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }

}
