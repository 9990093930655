import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ProviderData } from '@angular/core/src/view';
import { forEach } from '@angular/router/src/utils/collection';
import { retry } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'klassakt-viewcart',
  templateUrl: './viewcart.component.html'
})
export class KlassaktViewcartComponent extends KlassaktDashboardComponent implements OnInit {
  private sub: any;
  private selectedImageUrl: string;
  tempcount: number = 0;
  activeColor: string = '';
  previousval: number = 0;
  values: string;
  selected: any;

  async ngOnInit() {
    await super.ngOnInit();

    // this._dashboardService.selectedStudent = new Student;
  }

  public async selectcolor(clr, pId, cId) {
    var clrda = undefined;
   
    var ColorRes = await this._apiService.post('api/addColors', {
      OptionDetailId: clr.optionDetailsid, OptionMasterId: clr.optionMasterId, ProductOptionMasterId: clr.productOptionMasterid, ProductOptionDetailId: clr.productOptionDetailId, ProductId: pId, cartID: cId
    })
    
    if (ColorRes == 1) {
      clrda = await this._apiService.post('api/activeColor', {
        OptionDetailId: clr.optionDetailsid, ProductOptionDetailId: clr.productOptionDetailId, ProductId: pId, cartID: cId
      })
      
      this.toastr.successToastr('Added Color', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      this.loadpagedata();

    }
    else {
      this.toastr.errorToastr('Please try again!','', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    }
    
    {
      (<any>window).ga('send', 'event', { eventCategory: 'ViewCart', eventLabel: 'ViewCart', eventAction: 'Add Colour', eventValue: 10 });
    }  
  }
  
  protected async onAfterStudentsLoaded() {
    this.ishearts();
   this.account.highlightStudent = false;
   await this.loadpagedata();   
    //this.account.isheartPink = false;
  }

  async loadpagedata() {
  this.account.isheartPink = false;
    this.ishearts();
    this.account.highlightStudent = false;
    let iscartitemdeleted = false;
   
    let cart = await this._apiService.post<any[]>('api/getViewcartDetails', { AccountID: this._userService.data.id });
    console.log(cart)
    for (let i in cart) {
      let student = this.getStudent(cart[i].userID);
      if (student != undefined) {
        student.cart = cart[i];
        if (!iscartitemdeleted && cart[i].isCartchanged) {
          this.toastr.warningToastr("Dear Customer, please note there has been an update to your product selection. Please review it in the information tab before completing your purchase. Thank you.", "Notification!", { dismiss: 'click', position: 'top-full-width1', showCloseButton: true })
          iscartitemdeleted = false;
        }
      }
    }
    
    //for (let i of this.students) {
    //  if (i.cart && i.cart.isCartchanged == true) {
    //    this.toastr.warningToastr("Dear Customer, please note there has been an update to your product selection. Please review it in the information tab before completing your purchase. Thank you.", "Notification!", { dismiss: 'click', position: 'top-full-width1', showCloseButton: true})
    //  }
    //}
  }
  
  async deleteConfirm(userId: number, cartID: string) {
    if (cartID == "0") {
      var data = await this._apiService.post('api/deleteAllcart', { userID: userId, parentUserID: this._userService.data.id })
    } else {
      var data = await this._apiService.post('api/deleteOnecartitem', { userID: userId, parentUserID: this._userService.data.id, cartID: cartID })
    }
    $("#confirmDelete").modal('hide');
    this.toastr.successToastr('Item Successfully Deleted from Cart', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
    this.loadpagedata();
    {
      (<any>window).ga('send', 'event', { eventCategory: 'ViewCart', eventLabel: 'ViewCart', eventAction: 'Delete Item from Cart', eventValue: 10 });
    }  
  }
  //flip book code.
  public gotopayment() {
    {
      (<any>window).ga('send', 'event', { eventCategory: 'ViewCart', eventLabel: 'ViewCart', eventAction: 'Save and Continue', eventValue: 10 });
    }
    for (let i in this.students) {

     
      //if (this.students[i].cart.productDetail.productName == "FLIP BOOK") {

      //  this._router.navigateByUrl('/dashboard/(dashboardRouter:viewcartpayment)');
        

      //}
      if (this.students[i].cart && this.students[i].cart.savedImageStatus == "Failed") {

        this.toastr.errorToastr('Please select all the images and color to proceed', 'Images Mandatory!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return false;
      }
  }
  
    this._router.navigateByUrl('/dashboard/(dashboardRouter:viewcartpayment)');   
  }
 
 
  public setCartitemQuant(prodcartID, prodQuant,currentQuant) {
    this.cartid = prodcartID;
    this.quantity = prodQuant;
    if (currentQuant < prodQuant) {
      $("#Quantitychange").modal('show');
    } else {
      this.updateQuantity(prodcartID, prodQuant,0);
    }
  }

  public async updateQuantity(cartid: string, quantity: number, IsNewSet: number) {
    $("#Quantitychange").modal('hide');
    let status = await this._apiService.post('api/updateproductcartQuantity', { cartID: cartid, Quantity: quantity, IsNewSet: IsNewSet });
    this.toastr.successToastr('Quantity updated successfully', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
    this.loadpagedata();

  } 

  public async dismissModal() {
    $("#Quantitychange").modal('hide');
    this.loadpagedata();
  }

  public async submitPreviewImages() {
 
    if (this.selectedImages.length != this.requiredImages) {
      let msg = "Please select an image";
      if (this.requiredImages > 1)
        msg = "Please select " + this.requiredImages + " images";
      this.toastr.errorToastr(msg, 'Mandatory', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
    } else {
      
      let status = await this._apiService.post('api/savePreviewDetails', { userID: this.userId, parentUserID: this._userService.data.id, cartID: this.cartid, ProductId: this.productID, schoolpackgID: this.schoolpackgID, selectedImages: this.selectedImages });
      this.toastr.successToastr('Images Saved Successfully', 'Success ', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
      $("#PreviewModal").modal('hide');
      this.ishearts();
      this.account.highlightStudent = false;
      this.loadpagedata();
    }
  }

  public checkIstrueCol2(initialVal, tempCountdata) {
    if (initialVal != this.previousval) {
      this.tempcount = 0;
      tempCountdata = initialVal;
      this.previousval = initialVal;
    }
   

    if (initialVal == tempCountdata)
      return true;
    let tempNumber: number;
    tempNumber = initialVal;
    for (let j = initialVal; j < tempCountdata && tempNumber <= tempCountdata; j++) {
      if (tempNumber == tempCountdata) {
        if (this.NonCourselImageCount == tempCountdata) {
          this.tempcount = 0;
        }
        return true;
      }
      tempNumber += 4;
    }
    if (this.NonCourselImageCount == tempCountdata) {
      this.tempcount = 0;
    }
    return false;

  }
  public increamentval() {

    this.tempcount += 1;
    return this.tempcount;
  }
  public valueReset() {
    this.tempcount = 0;
    return this.tempcount;
  }
  protected get tempCountval() {
    return this.tempcount;
}

  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
