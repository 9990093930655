import { Component, OnInit, AfterViewInit,Pipe,PipeTransform, SecurityContext, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService,KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Http } from '@angular/http';
import { KlassaktPackagesComponent } from '../packages/packages.component';
import { KlassaktHeartsComponent } from '../hearts/hearts.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { Variable } from '@angular/compiler/src/render3/r3_ast';


//import { Document, Page, pdfjs } from 'react-pdf'

declare var $: any;
@Component({
  
  selector: 'klassakt-photos',
  templateUrl: './photos.component.html' 
})
@Pipe({ name: 'safe' })
export class KlassaktPhotosComponent extends KlassaktDashboardComponent implements OnInit {

  @Input()
  //url: string = "https://arjunwalmiki.blogspot.com/";
  //urlSafe: SafeResourceUrl;
 

  FindLength: any;
  totalSecondd: any;
  corselimgcount: number = 0;
  tempcount: number = 0;
  previousval: number = 0;
  iPhoneVer: any;
  isipad: any;
  public isIPhone: boolean = false;
  public checkScreenSize() {
  this.isIPhone = true;
    this.isipad = true;
    

  }
  public loaderService: KlassaktLoaderService;
  
 

  async ngOnInit() {
   // this.parametersFBTest = this.selectedStudent.schoolId;
    //this.urlSafe = this._sanitizer.bypassSecurityTrustResourceUrl(this.url);
    await super.ngOnInit();
  }

  public async onAfterStudentsLoaded() {
   
    
    this.setCountDown();
    this.setMasonry();
   // this.account.highlightStudent = true;
    if (navigator.appVersion.indexOf("iPhone") != -1 || navigator.appVersion.indexOf("iPad") != -1) {
     
      this.checkScreenSize();
      if (this.isIPhone && this.isipad) {
        this.iPhoneVer = "' To download this photo on  your iPhone or iPad , please press your finger down on the photo and hold it till you see the save photo option’";
      }
    }
  }

  protected EndDate: any;
  protected curDate: any;

  showFlipbook(schoolIdForFlipBook) {
    this.autoDisplayFlipbook(schoolIdForFlipBook)
  }
  autoDisplayFlipbook(vcode) {
   // $("#FlipBookContainer").html('<iframe width="100%" scrolling="no" src="https://designs.mydeievents.com/jq-3d-flip-book/index.html?id=' + vcode + '" ></iframe>');
    $("#FlipBookContainer").html('<iframe width="100%" scrolling="no" src="/assets/flipbook/' + vcode + '/index.html" ></iframe>');
  }

 
  showGallery(imageid: string) {
   // this.loaderService.show();
    KlassaktDashboardComponent._selectedimageid = imageid;
    this._router.navigate(['/', 'dashboard', { outlets: { dashboardRouter: ['gallery' ]}}]);
    //this.loaderService.hide();
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);

    
  }
 
  transform(schoolId: number) {
    let schoolID: number;
    schoolId = schoolId;
    return this._sanitizer.bypassSecurityTrustResourceUrl("'http://designs.mydeievents.com/jq-3d-flip-book/index.html?id='+schoolId");
  }
  
  public checkIstrueCol2(initialVal,tempCountdata)
  {
   
    if (initialVal != this.previousval)
    {
      this.tempcount = 0;
      tempCountdata = initialVal;
      this.previousval = initialVal;
    }
   
    if (initialVal == tempCountdata)
      return true;
    let tempNumber: number;
    tempNumber = initialVal;
    for (let j = initialVal; j < tempCountdata && tempNumber <= tempCountdata; j++)
    {
      if (tempNumber == tempCountdata)
      {
        if ((this.isHeartsOnly && this.selectedStudent.favImageWithoutCorselCount == tempCountdata) || (!this.isHeartsOnly && this.selectedStudent.NonfavImageWithoutCorselCount == tempCountdata))
        {
          this.tempcount = 0;
        }
        return true;
      }
      tempNumber += 4;
    }
    if ((this.isHeartsOnly && this.selectedStudent.favImageWithoutCorselCount == tempCountdata) || (!this.isHeartsOnly  && this.selectedStudent.NonfavImageWithoutCorselCount == tempCountdata)) {
      this.tempcount = 0;
    }
    return false;

  }
  public get students(): Student[]
  {
    return this._dashboardService.account.students;
  }
 
  public increamentval()
  {    
    this.tempcount += 1;
    return this.tempcount;
  }
  public valueReset()
  {
    this.tempcount = 0;
    return this.tempcount;
  }
  protected get tempCountval()
  {
    return this.tempcount;
  }

  
}
