import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'klassakt-offers',
  templateUrl: './offers.component.html'
})
export class KlassaktOffersComponent extends KlassaktDashboardComponent implements OnInit {

  async ngOnInit() {
    if (this.selectedStudent.orders == undefined) {
      this.selectedStudent.offers = new listData<any>();
      this.selectedStudent.offers.list = [];

      this.selectedStudent.offers.list.push({ orderID: 123, orderDate: '1/1/2011',orderDetails :{ name: '6x8-Indivudal', oldPrice: 120, price: 100, star: 3, imageCount: 1, imagePath: '/assets/images/2-0-products/6x8-Indivudal.png', products: [{}, {}] }});
      this.selectedStudent.offers.list.push({ orderID: 456, orderDate: '1/2/2011', orderDetails:{ name: '6x18 Class Photo Print', oldPrice: 120, price: 100, star: 4, imageCount: 1, imagePath: '/assets/images/2-0-products/6x18-class-photo-print.png', products: [{}, {}, {}, {}] } });
      this.selectedStudent.offers.list.push({ orderID: 789, orderDate: '1/3/2011', orderDetails:{ name: 'Wallet size 6', oldPrice: 120, price: 100, star: 2, imageCount: 1, imagePath: '/assets/images/2-0-products/Wallet-size-6.jpg', products: [{}, {}, {}] }});
      this.selectedStudent.offers.list.push({ orderID: 12312, orderDate: '1/4/2011', orderDetails:{ name: '8x10 Sibiling', oldPrice: 120, price: 100, star: 1, imageCount: 1, imagePath: '/assets/images/2-0-products/8x10-Sibiling-Portrait-with-mount.png', products: [{}] } });
      this.selectedStudent.offers.list.push({ orderID: 32112, orderDate: '1/5/2011', orderDetails:{ name: 'Class Photo Mount 6x14', oldPrice: 120, price: 100, star: 3, imageCount: 1, imagePath: '/assets/images/2-0-products/Class-Photo-Mount-6x14.png', products: [{}, {}, {}, {}, {}, {}, {}, {}] }});
      this.selectedStudent.offers.list.push({ orderID: 2231, orderDate: '2/1/2011', orderDetails:{ name: 'Canvas With Photo', oldPrice: 120, price: 100, star: 5, imageCount: 1, imagePath: '/assets/images/2-0-products/Canvas_with_photo.png', products: [{}, {}, {}, {}, {}] } });
      this.selectedStudent.offers.list.push({ orderID: 3123, orderDate: '3/1/2011', orderDetails:{ name: '6x18 Class Photo Print', oldPrice: 120, price: 100, star: 5, imageCount: 1, imagePath: '/assets/images/2-0-products/6x18-class-photo-print.png', products: [{}] } });
      this.selectedStudent.offers.list.push({ orderID: 123, orderDate: '4/1/2011', orderDetails:{ name: 'Class Photo Mount 6x14', oldPrice: 120, price: 100, star: 5, imageCount: 1, imagePath: '/assets/images/2-0-products/8x10-Sibiling-Portrait-with-mount.png', products: [{}, {}] } });
      this.selectedStudent.offers.list.push({ orderID: 123, orderDate: '5/1/2011', orderDetails:{ name: '8x10 Sibling', oldPrice: 120, price: 100, star: 3, imageCount: 1, imagePath: '/assets/images/2-0-products/Class-Photo-Mount-6x14.png', products: [{}, {}] } });
      this.selectedStudent.offers.list.push({ orderID: 123, orderDate: '6/1/2011', orderDetails:{ name: 'Canvas With Photo', oldPrice: 120, price: 100, star: 1, imageCount: 1, imagePath: '/assets/images/2-0-products/Canvas_with_photo.png', products: [{}] } });

    }
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
