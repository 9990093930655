import { Component, OnInit,Input } from '@angular/core';
import { KlassaktDashboardComponent } from './../dashboard'
import { Router, ActivatedRoute } from '@angular/router';
import { listData, KlassaktDashboardService } from '../../core/api/dashboard.sevice';
import { KlassaktUserService, KlassaktApiService } from '../../core';
import { ProviderData } from '@angular/core/src/view';
import { forEach } from '@angular/router/src/utils/collection';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { Http, ResponseContentType } from '@angular/http';
import { DeviceDetectorService  } from 'ngx-device-detector';
declare var $: any;
 


@Component({
  selector: 'klassakt-downloads',
  templateUrl: './downloads.component.html'
})
export class KlassaktDownloadsComponent extends KlassaktDashboardComponent implements OnInit {
  iPhoneVer: any;
  isipad: any;
  deviceInfo = null;
  isLoadingSpring: boolean = false;

 
 
  async ngOnInit() {
    await super.ngOnInit();

    if (this.account && this.selectedStudent && !this.selectedStudent.downloads) {

      this.selectedStudent.downloads = await this._apiService.post<listData<any>>('api/getDownloadImage', { studentID: this.selectedStudent.userID, AccountID: this._userService.data.id });
      if (!this.selectedStudent.downloads || !this.selectedStudent.downloads.list) {
        this.selectedStudent.downloads = new listData<any>();
        this.selectedStudent.downloads.list = [];

      }
      if (this.selectedStudent.photos) {
        for (let i = 0; i < this.selectedStudent.photos.list.length; i++) {
          if (!this.selectedStudent.photos.list[i].isWaterMark) {
            this.selectedStudent.downloads.list.push(this.selectedStudent.photos.list[i]);
          }

        }
      }
      if (navigator.appVersion.indexOf("iPhone") != -1 || navigator.appVersion.indexOf("iPad") != -1) {
        
        this.checkScreenSize();
        if (this.isIPhone && this.isipad) {
          this.iPhoneVer = "' To download this photo on  your iPhone or iPad , please press your finger down on the photo and hold it till you see the save photo option’";


        }

      }

    }
  }

  downloadFile(data) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
  } 
  public isIPhone: boolean = false;

  public checkScreenSize() {
    
    this.isIPhone = true;
    this.isipad = true;
   
  }
   
  protected async onVisible() {
    
    if (this.account && this.selectedStudent && !this.selectedStudent.downloads) {

      this.selectedStudent.downloads = await this._apiService.post<listData<any>>('api/getDownloadImage', { studentID: this.selectedStudent.userID, AccountID: this._userService.data.id });
      if (!this.selectedStudent.downloads || !this.selectedStudent.downloads.list) {
        this.selectedStudent.downloads = new listData<any>();
        this.selectedStudent.downloads.list = [];
       
      }
      for (let i = 0; i < this.selectedStudent.photos.list.length; i++) {
        if (!this.selectedStudent.photos.list[i].isWaterMark) {
          this.selectedStudent.downloads.list.push(this.selectedStudent.photos.list[i]);
        }
        
      }
      if (navigator.appVersion.indexOf("iPhone") != -1 || navigator.appVersion.indexOf("iPad") != -1) {
      
        this.checkScreenSize();
        if (this.isIPhone && this.isipad) {
          this.iPhoneVer = "' To download this photo on  your iPhone or iPad , please press your finger down on the photo and hold it till you see the save photo option’";
         
         
          }
        
      }
     
    }
    this.setMasonry();
  }
  
  private isAllSelected: boolean = false;
  public selectImage(p) {
    p.isSelected = !p.isSelected;
  
  }
  async getImageonSelectAll()
  {
    
    this.isAllSelected = !this.isAllSelected;
      for (let i = 0; i < this.selectedStudent.downloads.list.length; i++) {
        this.selectedStudent.downloads.list[i].isSelected = this.isAllSelected;

      
    }
    
  }
 
  public downloadUrls: any[];

  //private delay(ms: number) {
  //  return new Promise(resolve => setTimeout(resolve, ms));
  //}

  async Download() {
      let isSelected: boolean = false;
      this.downloadUrls = [];
      for (let i = 0; i < this.selectedStudent.downloads.list.length; i++) {

        if (this.selectedStudent.downloads.list[i].isSelected) {
          isSelected = true;
          let img = this.selectedStudent.downloads.list[i].imageUrl.replace('http', 'https');
          setTimeout(() => { this.downloadUrls.push(this._sanitizer.bypassSecurityTrustResourceUrl(img.replace('/upload/', '/upload/fl_attachment/'))); }, 10);

        }

      }
      if (!isSelected)
        this.toastr.errorToastr('Click on a photo to select and then download.', 'No photo selected', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    
  }
  protected async onAfterStudentsLoaded() {
   await this.onVisible();
   
  }
 
  public constructor(   
    public http : Http,
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer
    ) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
