import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ng6-toastr-notifications';
import { klassaktAppRoutes } from './app.routing';
import { KlassaktAppComponent } from './app.component';
import { KlassaktNotFoundComponent } from './not-found.component';
import { KlassaktAppTitleService } from './app-shell/app-title.service';
import { MyDatePickerModule } from 'mydatepicker';
import { TooltipModule, CollapseModule } from 'ngx-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DeviceDetectorModule } from 'ngx-device-detector';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// app module components
import { KlassaktCoreModule } from '../app/core';
import { KlassaktDashboardComponent, KlassaktDashboardMainComponent } from './app-shell';
import { KlassaktPhotosComponent } from './app-shell/photos/photos.component';
import { KlassaktProductsComponent } from './app-shell/products/products.component';
import { KlassaktPackagesComponent } from './app-shell/packages/packages.component';
import { KlassaktOrdersComponent } from './app-shell/orders/orders.component';
import { KlassaktDownloadsComponent } from './app-shell/downloads/downloads.component';
import { KlassaktOffersComponent } from './app-shell/offers/offers.component';
import { KlassaktDashboardService, KlassaktLoaderService } from './core/api/dashboard.sevice';
import { KlassaktGalleryComponent } from './app-shell/gallery/gallery.component';
import { KlassaktViewcartComponent } from './app-shell/viewcart/viewcart.component';
import { KlassaktViewcartpaymentComponent } from './app-shell/viewcartpayment/viewcartpayment.component';
import { KlassaktDashboardSectionsComponent } from './app-shell/dashboard-sections/dashboard-sections.component';
import { KlassaktStaticPagesComponent } from './app-shell/staticPages/staticPages.component';
import { KlassaktOrderSummaryComponent } from './app-shell/orderSummary/orderSummary.component';
import { KlassaktSupportComponent } from './app-shell/support/support.component';
import { KlassaktHeartsComponent } from './app-shell/hearts/hearts.component';
import { KlassaktPackageDetailsComponent } from './app-shell/packages/details/packageDetails.component';
import { KlassaktProductDetailsComponent } from './app-shell/products/details/productDetails.component';
import { KlassakChangePasswordComponent } from './app-shell/changePassword/changePassword.component';
import { KlassaktForgotPasswordComponent } from './app-shell/forgotPassword/forgotPassword.component';
import { KlassaktRegisterComponent } from './app-shell/register/register.component';
import { KlassaktOrderConfirmationComponent } from './app-shell/orderConfirmation/orderConfirmation.component';
import { KlassaktResetPasswordComponent } from './app-shell/resetPassword/resetPassword.component';
import { KlassaktAccountComponent } from './app-shell/account/account.component';
import { SocialLoginModule, AuthService, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';




export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("665726913841338")

      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider("262653990981-aj5sgnac2o5t2e73ul03o3b4h7t96sb7.apps.googleusercontent.com")
      },
    ]
  );
  return config;
}



@NgModule({
  imports: [
    BrowserModule,
    MyDatePickerModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    RouterModule,
    RouterModule.forRoot(klassaktAppRoutes),
    ToastrModule.forRoot(),
    KlassaktCoreModule.forRoot(),
    SocialLoginModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    KlassaktAppComponent,
    KlassaktNotFoundComponent,
    KlassaktPhotosComponent,
    KlassaktProductsComponent,
    KlassaktPackagesComponent,
    KlassaktOrdersComponent,
    KlassaktDownloadsComponent,
    KlassaktOffersComponent,
    KlassaktGalleryComponent,
    KlassaktDashboardSectionsComponent,
    KlassaktStaticPagesComponent,
    KlassaktViewcartComponent,
    KlassaktViewcartpaymentComponent,
    KlassaktOrderSummaryComponent,
    KlassaktSupportComponent,
    KlassaktHeartsComponent,
    KlassaktPackageDetailsComponent,
    KlassaktProductDetailsComponent,
    KlassakChangePasswordComponent,
    KlassaktForgotPasswordComponent,
    KlassaktRegisterComponent,
    KlassaktOrderConfirmationComponent,
    KlassaktResetPasswordComponent,
    KlassaktDashboardMainComponent,
    KlassaktAccountComponent


  ],
  providers: [

    KlassaktAppTitleService,
    KlassaktLoaderService,
    KlassaktDashboardService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [KlassaktAppComponent]
})
export class KlassaktAppModule {

}





