import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, SupportDetail, StudentDetailToAdd } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'klassakt-ChangePassword',
  templateUrl: './changePassword.component.html'
})
export class KlassakChangePasswordComponent extends KlassaktDashboardComponent implements OnInit {

  public newObject: any = {};

  protected async onAfterStudentsLoaded() {
    this.newObject.IsAgreed = true;
  }

  async ngOnInit() {
    await super.ngOnInit();
  }

  async CheckForValidation() {
    var patt = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9\w~@@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,16})$/;
    if (patt.test(this.newObject.New )) { }
    else {
      this.toastr.errorToastr("Password must be of atleast 8 characters with 1 number, 1 lowercase and 1 uppercase.", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.newObject.New = "";
      return false;
    }
  }

  async ChangePassSubmit() {
    if (this.newObject.IsAgreed == true) {
      if (this.newObject.Current != "" && this.newObject.Current != null) {
        if (this.newObject.New != "" && this.newObject.New != null && this.newObject.Conform != "" && this.newObject.Conform != null) {
          if (this.newObject.New == this.newObject.Conform) {
            let s = await this._apiService.post<string>('api/Register/ChangePassword', { newPassword: this.newObject.New, usId: this._userService.data.id });
            this.toastr.successToastr('Password changed successfully.', 'Success', {timeOut: 25000, position: 'top-full-width1', showCloseButton: true});
            this.newObject.New = "";
            this.newObject.Current = "";
            this.newObject.Conform = "";
            this._router.navigateByUrl('/');
            //this.staticpage.SetAccount(this.primaryMail, this.newObject.New);
          }
          else {
            this.toastr.errorToastr('New password and Conform password must be same', 'Error', {timeOut: 25000, position: 'top-full-width1', showCloseButton: true});
            this.newObject.New = ""; this.newObject.Conform = "";
          }
        }
      } else {
        this.toastr.errorToastr('Please fill Current Password fields.', 'Error', {timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
      }
    } else {
      this.toastr.errorToastr('You need to agree for change password else you can not change password.', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
    }
   
  }

  async CheckForCorrectPass() {
    let s = await this._apiService.post<string>('api/Register/checkForOldPassword', { OldPass: this.newObject.Current, usId: this._userService.data.id });
    if (s != "true") { this.toastr.errorToastr('Password entered is incorrect, please enter correct Password.', 'Error', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true }); this.newObject.Current = ""; }
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
