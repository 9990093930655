import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, SupportDetail, KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'klassakt-forgotPassword',
  templateUrl: './forgotPassword.component.html'
})

export class KlassaktForgotPasswordComponent {
  emailId: string = "";

  constructor(
    // private formBuilder: FormBuilder,
    private loaderService: KlassaktLoaderService,
    private _userService: KlassaktUserService,
    private _router: Router,
    private _apiService: KlassaktApiService,
    protected toastr: ToastrManager
  ) {
    
  }

  async doProperMailID() {
   
    if (this.emailId != null && this.emailId != '' && this.emailId != undefined)
    {
      var data = await this._apiService.post('api/Register/ForgotenPassword', { PrimaryEmail: this.emailId });
      if (data == '' || data == undefined || data == null || data == "false") {
        this.toastr.errorToastr('Entered mail Id is not registered. Please check and try again.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return;
      }
      else
      {
        this.toastr.successToastr('Please click the link sent to your registered e-mail address to reset your password.', 'sucess', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        this._router.navigate(['/']);
      }
    }
  
    }
  }
