import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { Observable, observable } from "rxjs";
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Account, listData, Student, SupportDetail, StudentDetailToAdd, paymentDetails } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { KlassaktDashboardComponent } from '..';
import { Http } from '@angular/http';
import { debug } from 'util';

@Component({
  selector: 'klassakt-dashboard-sections',
  templateUrl: './dashboard-sections.component.html'
})
export class KlassaktDashboardSectionsComponent extends KlassaktDashboardComponent implements OnInit {
 

  protected async onAfterStudentsLoaded() {
    this._dashboardService.DashboardSection = this;
  }

  async ngOnInit() {
    await super.ngOnInit();
    
  }

  public selectedTabName: any = "Photos";
  protected selectedTabInstance: any;
  
  public onTabSelection(newInstance: any, seletedTab) {
    setTimeout(async () => {
      if (this.selectedTabInstance && this.selectedTabInstance.onHide)
        await this.selectedTabInstance.onHide();
      if (newInstance && newInstance.onVisible)
        await newInstance.onVisible();
      this.selectedTabInstance = newInstance;
    }, 150);
    this.selectedTabName = seletedTab;
    //this.goToPage('/dashboard/(dashboardRouter:product)');
    this.setMasonry();
    //console.log(this.isHeartsOnly);
    //this.ishearts();
    //this.account.isheartPink == false;
   
  }
 
  getTotalSelectedPictureCount(): number {

    return this.selectedTabName.length;
    debugger;
  }
  getTotalTrueSelectedPictureCount(): number {
    //console.log(JSON.stringify(this.getTotalSelectedPictureCount))

    return this.selectedTabName.filter(e => e.photos === "photos").length;
    debugger;
  }
  
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
   
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
