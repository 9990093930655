import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';

import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { KlassaktDashboardSectionsComponent } from '../dashboard-sections/dashboard-sections.component';


@Component({
  selector: 'klassakt-hearts',
  templateUrl: './hearts.component.html'
})
export class KlassaktHeartsComponent extends KlassaktDashboardSectionsComponent implements OnInit {
 
  protected async onAfterStudentsLoaded() {
   
    ///favImageWithoutCorselCount;
    this.account.isheartPink = false;
   
    this.account.highlightStudent = true;
   // console.log(viewShortlistedCount);
    
  }
 
  async ngOnInit() {
    await super.ngOnInit();
  }

  //async hearted(imgID){
  //  let result = await this._apiService.post<boolean>('api/addproductcart', { studentUserId: this.selectedStudent.userID, accountId: this._userService.data.id, refID: imgID,typeID:1 });
  //  if (!result) {
  //    this.toastr.successToastr('There was some error', 'Error!', { dismiss: 'click' });
  //  }
  //  return result;
  //}
}
