import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { KlassaktApiService } from './api';
import { KlassaktPermissionGuard, KlassaktPermissionDirective } from './auth';
import {
  KlassaktBase64Service, KlassaktJwtTokenService, KlassaktPermissionService,
  KlassaktUserTokenStoreService, KlassaktUserService
} from './auth';
import { KlassaktStorageService } from './common';
import { KlassaktRedirectComponent } from './routing';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    KlassaktRedirectComponent,

    KlassaktPermissionDirective,
  ],
  exports: [
    KlassaktRedirectComponent,

    KlassaktPermissionDirective,
  ],
  entryComponents: [
  ],
  providers: [
  ]
})
export class KlassaktCoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: KlassaktCoreModule,
      // list here app wide singletons
      providers: [
        // singleton services
        KlassaktApiService,
        KlassaktBase64Service,
        KlassaktJwtTokenService,
        KlassaktPermissionService,
        KlassaktUserTokenStoreService,
        KlassaktUserService,

        KlassaktStorageService,

        // guards
        KlassaktPermissionGuard
      ],
    };
  }
}
