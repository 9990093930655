import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { KlassaktAppTitleService } from './app-shell/app-title.service';
import {
  KlassaktApiService,
  KlassaktUserService,
  KlassaktStorage,
  KlassaktStorageService
} from '../app/core';
import { Router, NavigationEnd } from '@angular/router';
import { KlassaktLoaderService } from './core/api/dashboard.sevice';
declare let ga: Function;


class KlassaktAppComponentStorage {
  public sidenavOpened = true;
}

@Component({
  selector: 'Klassakt-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class KlassaktAppComponent implements OnInit, OnDestroy, AfterViewInit {
  private _storage: KlassaktStorage<KlassaktAppComponentStorage>;
  private _loadingChangedSubscription: Subscription;
  private _userChangedSubscription: Subscription;
  private _loggedOutSubscription: Subscription;
  private _username = '';
  private _defaultAppName = 'KlassAkt';
  private _title = 'KlassAkt';
  src 

  source: string = '';
  onLoadFunc(myIframe) {
    this.source = myIframe.contentWindow.location.href;
  }

  public get title(): string {
    return this._title;
  }
  public get $username(): string {
    return this._username;
  }
  public get $fullName(): string {
    return this._userService.isLoggedIn ? this._userService.data.fullName : '';
  }
  private _isLoading = false;
  public get $isLoading() {
    return this._isLoading;
  }
  public folderName: string = "pages";
  constructor(
    private sanitizer: DomSanitizer,
    private _router: Router,
    private loaderService: KlassaktLoaderService,
    private _appTitleService: KlassaktAppTitleService,
    private _userService: KlassaktUserService,
    private _apiService: KlassaktApiService,
    private _storageService: KlassaktStorageService) {
    let loadStorage = () => this._storage = this._storageService.storage('KlassaktAppComponent', new KlassaktAppComponentStorage());
    loadStorage();
    this._setTitle(this._defaultAppName);
    this._loadingChangedSubscription = this._apiService.loadingChanged.pipe(
      debounceTime(100)).subscribe(data => {
        this._isLoading = data.isLoading;
      });
    this._isLoading = false;
    this._userChangedSubscription = this._userService.userChanged.subscribe(() => {
      this._setUsername();
      loadStorage();
    });
    this._router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');

      }

    });
    this._loggedOutSubscription = this._userService.loggedOut.subscribe(() => {
      this._router.navigate(['/']);
    });
    this._setUsername();

   

  }
  private _setTitle(title: string) {
    this._appTitleService.setTitle(title);
    this._title = title;
  }
  private _setUsername() {
    let username = '';
    if (this._userService.isLoggedIn && this._userService.data) {
      username = this._userService.data.username;
    }
    this._username = username;
  }
  ngAfterViewInit() {
  }
  ngOnInit() {
    console.log('KlassaktAppModule');
  }
  ngOnDestroy() {
    this._loadingChangedSubscription.unsubscribe();
    this._userChangedSubscription.unsubscribe();
    this._loggedOutSubscription.unsubscribe();
  }
 
}


