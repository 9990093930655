import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { Http } from '@angular/http';

import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'klassakt-gallery',
  templateUrl: './gallery.component.html'
})
export class KlassaktGalleryComponent extends KlassaktDashboardComponent implements OnInit, OnDestroy {
  private isviewMoreProduct: boolean = false;
  private isviewMorePackage: boolean = false;
  public loaderService: KlassaktLoaderService;
  private viewMoreProduct: string = "View More";
  private viewMorePackage: string = "View More";
  private sub: any;
  public imgPopupActiveIndex: number = 0;
  protected async ProductLoad() {

    if (this.account && this.selectedStudent && this.selectedStudent.products == undefined) {
      if (this.selectedStudent.isSibling == 1) {
        this.selectedStudent.products = await this._apiService.post<listData<any>>('api/getProductDetails', { schoolID: this.selectedStudent.schoolId, isSibling: 1 });
      } else {
        this.selectedStudent.products = await this._apiService.post<listData<any>>('api/getProductDetails', { schoolID: this.selectedStudent.schoolId, isSibling: 0 });
      }

      console.log("Products" + this.selectedStudent.products.list);
    }

  }

  public OnSlideJump(jump: number, event, eventCount: number) {
    {
      //this.loaderService.show();

      $('#carouselExampleIndicators').carousel(jump);
      // event.target.classList.toggle('active');
      for (let i = 0; i < eventCount; i++) {
        var iddata = i.toString();
        document.getElementById(iddata).classList.remove('active');
      }
      event.target.classList.add('active');
      this.galleryImageIndex = jump;
    //this.loaderService.hide();
    }
    }
  protected async PackageLoad() {

    if (this.account && this.selectedStudent && this.selectedStudent.packages == undefined) {

      if (this.selectedStudent.isSibling == 1) {
        this.selectedStudent.packages = await this._apiService.post<listData<any>>('api/getPackageDetails', { schoolID: this.selectedStudent.schoolId, isSibling: 1 });
      } else {
        this.selectedStudent.packages = await this._apiService.post<listData<any>>('api/getPackageDetails', { schoolID: this.selectedStudent.schoolId, isSibling: 0 });
      }
      //console.log("packsages" + this.selectedStudent.packages.list);
    }

  }

  public get3Product() {
    return this.selectedStudent.products.list.slice(0, 3);
  }
  private imageUrl: any;
  async ngOnInit() {
    await super.ngOnInit();
    this.sub = this._route.params.subscribe(async params => {
      setTimeout(() => document.getElementById('gallaryMainContain').scrollIntoView(), 250);
      if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0) {
        setTimeout(() => {
          $('.thumbnails').removeClass('active');
          var lis = $("#content-2 li");
          var li = lis.filter("li[imgId = '" + KlassaktDashboardComponent._selectedimageid + "']");
          li.find('img').addClass('active');
          var imgIndx = lis.index(li);
          $('#carouselExampleIndicators').carousel(imgIndx);
          this.galleryImageIndex = imgIndx;
          setTimeout(() => $("#content-2").mThumbnailScroller("scrollTo", $('#thumb_' + this.galleryImageIndex)), 250)
        }, 250);

      }
    });

    $("#content-2").mThumbnailScroller({
      type: "click-60",
      theme: "buttons-out"
    });
  }
  private thumbnailCarousel: any;

  public activeElement = 1;
  public selectedItem(id) {
    this.activeElement = id;
  }

  protected async onAfterStudentsLoaded() {
    await this.ProductLoad();
    await this.PackageLoad();
  
    if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0) {
      let countData: number = 0;
      for (let contact of this.selectedStudent.photos.list) {
        if (contact.klassaktImageID == KlassaktDashboardComponent._selectedimageid) {
          $('#carouselExampleIndicators').carousel(countData);
          this.galleryImageIndex = countData;
          var iddata = countData.toString();
          document.getElementById(iddata).classList.add('active');
          $("#content-2").mThumbnailScroller("scrollTo", "#" + this.galleryImageIndex);
          break;
        }
        countData += 1;
      }
    }
    }
  public setCourselImgPopup(tempIndex: number)
  {
  
    this.imgPopupActiveIndex = tempIndex;
    let activeItem: any = $('.photoenlargpopup .carousel-item.active').length;

    //if (activeItem > 1) {
    // $('.photoenlargpopup .carousel-item.active').removeClass('active');
    // $('.photoenlargpopup .carousel-item:eq('+tempIndex+')').addClass('active');
    //}
  }

  public setclosePopupImg(tempIndex: number) {
    this.imgPopupActiveIndex = tempIndex;
    $('.photoenlargpopup .carousel-item.active').removeClass('active');
  }
  private galleryImageIndex: number = 0;
  public setNextThumbnail() {
    
    for (let i = 0; i < this.selectedStudent.photos.list.length; i++) {
      var iddata = i.toString();
      document.getElementById(iddata).classList.remove('active');
    }
    this.galleryImageIndex = this.galleryImageIndex + 1;
    if (this.galleryImageIndex >= this.selectedStudent.photos.list.length) this.galleryImageIndex = 0;

    if (this.galleryImageIndex == 0) {
      var tempid = this.galleryImageIndex.toString();
      document.getElementById(tempid).classList.add('active');
      if (this.thumbnailCarousel)
        this.thumbnailCarousel.setCurrent(this.galleryImageIndex);
    }
    else {
      var tempid = this.galleryImageIndex.toString();
      document.getElementById(tempid).classList.add('active');
      if (this.thumbnailCarousel)
        this.thumbnailCarousel.setCurrent(this.galleryImageIndex);
    }
    $("#content-2").mThumbnailScroller("scrollTo", "#" + this.galleryImageIndex);
    
  }

  private setThumbnail() {
    $('.thumbnails').removeClass('active');
    if (this.galleryImageIndex >= this.selectedStudent.photos.list.length) this.galleryImageIndex = 0;
    if (this.galleryImageIndex < 0) this.galleryImageIndex = this.selectedStudent.photos.list.length - 1;
    $('thumb_' + this.galleryImageIndex).addClass('active');
    $("#content-2").mThumbnailScroller("scrollTo", $('#thumb_' + this.galleryImageIndex));
    
  }
  public setPreThumbnail() {
   
    for (let i = 0; i < this.selectedStudent.photos.list.length; i++) {
      var iddata = i.toString();
      document.getElementById(iddata).classList.remove('active');
    }
    this.galleryImageIndex = this.galleryImageIndex - 1;
    if (this.galleryImageIndex < 0) this.galleryImageIndex = this.selectedStudent.photos.list.length - 1;
    if (this.thumbnailCarousel) this.thumbnailCarousel.setCurrent(this.galleryImageIndex);

    var tempid = this.galleryImageIndex.toString();
    document.getElementById(tempid).classList.add('active');

    $("#content-2").mThumbnailScroller("scrollTo", "#" + this.galleryImageIndex);
  }

  OnClickProductViewMore() {
    this.isviewMoreProduct = !this.isviewMoreProduct;
    if (this.isviewMoreProduct)
      this.viewMoreProduct = "View Less";
    else
      this.viewMoreProduct = "View More";
  }
  OnClickPackageViewMore() {
    this.isviewMorePackage = !this.isviewMorePackage;
    if (this.isviewMorePackage)
      this.viewMorePackage = "View Less";
    else
      this.viewMorePackage = "View More";
  }
  ngAfterViewInit(): void {

  }
  //public goTo(location: string): void {
  //  window.location.hash = location;
  //}

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}

