import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { KlassaktJwtTokenService } from './jwt-token.service';
import { KlassaktToken } from './token';
import { KlassaktUserTokenStoreService } from './user-token-store.service';
import { KlassaktStorageService } from './../common/storage.service';
import { Account } from '../api/dashboard.sevice';

@Injectable()
export class KlassaktUserService {

    private _isLoggedIn = false;
    private _userChangedSource = new Subject();
    private _loggedOutSource = new Subject();
    private _data: KlassaktToken;
    private _token: string;
    private _refreshToken: string;

    userChanged = this._userChangedSource.asObservable();
    loggedOut = this._loggedOutSource.asObservable();

    constructor(
        private _storageService: KlassaktStorageService,
        private _jwtTokenService: KlassaktJwtTokenService,
        private _userTokenStoreService: KlassaktUserTokenStoreService
    ) {
        this._init();
    }

    // private
    private _init() {
        this.setToken(this._userTokenStoreService.getToken());
        this.setRefreshToken(this._userTokenStoreService.getRefreshToken());
    }
    private _notifyUserChanged() {
        this._userChangedSource.next({});
    }

    // public
    get data(): KlassaktToken { return this._data; }
    get token() { return this._token; }
    get refreshToken() { return this._refreshToken; }
    get isLoggedIn() { return this._isLoggedIn; }
    public setToken(token: string): void {
        let data = this._jwtTokenService.decryptJwt(token);
        if (data != null) {
            this._userTokenStoreService.setToken(token);
            this._data = data;
            this._token = token;
          this._isLoggedIn = true;
        } else {
            this._userTokenStoreService.setToken(null);
            this._data = null;
            this._token = null;
            this._isLoggedIn = false;
            console.log('user service recieved EMPTY token');
        }
        this._notifyUserChanged();
    }
    public setRefreshToken(refreshToken: string): void {
        this._userTokenStoreService.setRefreshToken(refreshToken);
        this._refreshToken = refreshToken;
    }
    public logout() {
        this.setToken(null);
        this.setRefreshToken(null);
        this._loggedOutSource.next({});
        this._storageService.clean();
    }
  public cacheAccountData(data: Account): void {
    setTimeout(() => {
      this._storageService.setItem('user-accounts-data', JSON.stringify(data));
      this._storageService.setItem('user-accounts-dateTime', new Date().getTime().toString());
    }, 350);
  }
  public getCacheAccountData(): Account {
    try {
       var lastStoredTime = parseInt(this._storageService.getItem('user-accounts-dateTime'));
      if ((new Date().getTime() - lastStoredTime) < 1800000) { //1800000 is equal to 30 minutes, milliseconds * 1000 * 60 * 30
        return JSON.parse(this._storageService.getItem('user-accounts-data'));
      }
      return null;
    }
    catch (e) {
      return null;
    }
  }
}
