import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { KlassaktDashboardComponent } from '../..';
import { KlassaktPackagesComponent } from '../packages.component';
declare var $: any;
@Component({
  selector: 'klassakt-package',
  templateUrl: './pacakgeDetails.component.html'
})
export class KlassaktPackageDetailsComponent extends KlassaktPackagesComponent implements OnInit, OnDestroy {
  private sub: any;
  private packageId: any;
  public SelectedPackage: any;
  private galleryImageIndex: number = 0;
  private courselClassAdd = 0;
  private thumbnailCarousel: any;
    imageUrl: any;
  protected async onAfterStudentsLoaded() {
    if (!this.packageId) {
      this._router.navigateByUrl('/dashboard');
      return;
    }
      if (!this.selectedStudent.packages) {
        await this.onVisible();
      }
      if (this.selectedStudent.packages && this.selectedStudent.packages.list) {
        this.SelectedPackage = this.selectedStudent.packages.list.find(a => a.packageId == this.packageId);
    }
    setTimeout(() => {
      this.thumbnailCarousel = $('#sliderThumbnail').elastislide();
      this.thumbnailCarousel.setCurrent(this.galleryImageIndex);
      document.getElementById("0").classList.add('active');
    }, 150);
  }

  public OnSlideJump(jump: number, event, eventCount: number) {
   
    $('#myCarousel').carousel(jump);
    for (let i = 0; i < eventCount; i++) {
      var iddata = i.toString();
      document.getElementById(iddata).classList.remove('active');
    }
    //document.getElementsByClassName("packageTemp")[0].classList.remove('active');
    event.target.classList.add('active');
    this.galleryImageIndex = jump;
  }

  public setNextThumbnail() {
    for (let i = 0; i < this.SelectedPackage.products.length; i++) {
      var iddata = i.toString();
      document.getElementById(iddata).classList.remove('active');
    }
    this.galleryImageIndex = this.galleryImageIndex + 1;
    if (this.galleryImageIndex >= this.SelectedPackage.products.length) this.galleryImageIndex = 0;

    if (this.thumbnailCarousel) {
      if (this.galleryImageIndex == 0 ) {
        this.thumbnailCarousel.setCurrent(this.galleryImageIndex);
        var tempid = this.galleryImageIndex.toString();
        document.getElementById(tempid).classList.add('active');
      }

      else {
        this.thumbnailCarousel.setCurrent(this.galleryImageIndex + 1);
        var tempid = this.galleryImageIndex.toString();
        document.getElementById(tempid).classList.add('active');
      }
      
    }

  }
  public setPreThumbnail() {
    for (let i = 0; i < this.SelectedPackage.products.length; i++) {
      var iddata = i.toString();
      document.getElementById(iddata).classList.remove('active');
    }
    this.galleryImageIndex = this.galleryImageIndex - 1;
    if (this.galleryImageIndex < 0) this.galleryImageIndex = this.SelectedPackage.products.length - 1;
    if (this.thumbnailCarousel) this.thumbnailCarousel.setCurrent(this.galleryImageIndex);

    var tempid = this.galleryImageIndex.toString();
    document.getElementById(tempid).classList.add('active');
  }
  async ngOnInit() {
    await super.ngOnInit();
    this.sub = this._route.params.subscribe(async params => {
      this.packageId = params['packageId'];    
     
    });
  }

  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }
  //async addProdctCarts(ProductId: string) {

  //  var data = await this._apiService.post('api/addproductcart', { userID: this.selectedStudent.userID, ProductId: ProductId, packageID: '00000000-0000-0000-0000-000000000000', parentUserID: this._userService.data.id })
  //  this.toastr.successToastr('Item Successfully Added To Cart', 'Success!', { dismiss: 'click' });
  //} 

}
