import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktStaticPagesComponent } from '../staticPages/staticPages.component';
import { KlassaktDashboardService, Student, listData, KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { logging } from 'protractor';
import { FormControl, Validators } from '@angular/forms';

import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { getTypeNameForDebugging } from '@angular/common/src/directives/ng_for_of';
import { log } from 'util';
import { splitNsName } from '@angular/compiler';



declare var $: any;
declare var FB: any;
@Component({
  selector: 'klassakt-register',
  templateUrl: './register.component.html'
})
export class KlassaktRegisterComponent implements OnInit, OnDestroy {
  _storageService: any;
  txt: string;
  control: FormControl;
  public disableDetailsForm = false;
  public data: any;
  public userData: any;
  providerShow: boolean = false;
  SocialIdShow: boolean = false;
  SocialProvider: string = "";
  SocialId: string = "";
  lastName: string = "";
  relationName: any;
  primaryContactNo: any;
  constructor(
   
   
    //private facebookService: FacebookService,
    private loaderService: KlassaktLoaderService,
    private _userService: KlassaktUserService,
    private _router: Router,
    private _apiService: KlassaktApiService,
    protected toastr: ToastrManager,
    protected _route: ActivatedRoute,
    private socialAuthService: AuthService,
   // private socialAuthService:SocialAuthService,
  ) {  }
  
  
  stepEnabled: number = 0;
  qrCode: string = "";
  emailId: string = "";

  classSectionId: string = "0";
  Fulldetail: any;
  Userdetail: any;
  schooldetail: any;
  sectiondata: any;
  termsAndConditionSchool: boolean = false;
  PasswordSavedSchool: boolean = false;
  firstName: string = "";
  LastName: string = "";
  userPassword: string = "";
  conformPassword: string = "";
  Password: string = "";

  //this.userData = this._userService.getData();

  //social logout botton
//logout() {
//  this.socialAuthService.signOut().then(data => {
//    this._userService.logOut();
//  });
//}


  onclickFaceSocial() {
    
    var qrCode = this.qrCode;
    if (qrCode == undefined || qrCode == '') {
      this.toastr.errorToastr('Please enter the QR Code.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    else {
      this.facebookLogin();
    }

  }

  facebookLogin() {

    this.providerShow = true;
    this.SocialIdShow = true;
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {

      //this.user = userData;
      const splitString = userData.name.split(" ");
      console.log(splitString);
      console.log(userData)
      
      if (userData != undefined || userData != null) {
        this.emailId = userData.email;
        this.SocialId = userData.id;
        this.firstName = splitString[0];
        this.lastName = splitString[1];
        this.userPassword = userData.id;
        this.conformPassword = userData.id;
        this.firstName = splitString[0];
        this.SocialProvider = userData.provider;
      }
      
    });
    
  }
 
  onclickSocial() {
    var qrCode = this.qrCode;
    if (qrCode == undefined || qrCode == '') {
      this.toastr.errorToastr('Please enter the QR Code.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    else {

      this.googleLogin();
    }

  }

  googleLogin() {

    this.providerShow = true;
    this.SocialIdShow = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {

      const splitString = userData.name.split(" ");
      console.log(splitString);
      console.log(userData);

      if (userData != undefined || userData != null) {
        this.emailId = userData.email;
        this.SocialId = userData.id;
        this.firstName = splitString[0];
        this.lastName = splitString[1];
        this.userPassword = userData.id;
        this.conformPassword = userData.id;
        this.firstName = splitString[0];
        this.SocialProvider = userData.provider;

      }

    });
  }

  shiftSlide(enableType: number) {
    this.stepEnabled = enableType;
    $('#registerWizard').carousel(enableType);
  }

  protected async onAfterStudentsLoaded() {
    this.Userdetail.IsAgreed = true;
  }
  async validate(enableType: number) {
    debugger
    //$('#divStep2').collapse('close');
    var data = await this._apiService.post('api/Register/DoQRExists', { QrCode: this.qrCode });
    if (data) {
      var qrCode = this.qrCode;
      var emailId = this.emailId;

      if (qrCode == undefined || qrCode == '') {
        this.toastr.errorToastr('Please enter the QR Code.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return;
      }
      else if (emailId == undefined || emailId == '') {
        this.toastr.errorToastr('Please enter the EmailID Code.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return;
      }
      else {
        var res = await this._apiService.post('api/Register/IsParentMailIdIsPresent', { mailId: this.emailId, QrCode: this.qrCode });
        if (res == "registeredParent") {
          this.toastr.errorToastr('You are already registered for this student.', 'Error', { timeOut: 35000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
        else if (res == "IncorrectSchool") {
          this.toastr.errorToastr('This account is already used for a different School. Kindly create a new account with a different email id to add this student.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
        else {
          this.Fulldetail = await this._apiService.post('api/Register/NewSignUp', { mailId: this.emailId, QrCode: this.qrCode });
          //console.log(this.Fulldetail);
          if (this.Fulldetail != undefined && this.Fulldetail != null && this.Fulldetail.length != 0) {
            this.Userdetail = this.Fulldetail[0];

            if (this.Fulldetail == "existing") {
              this.toastr.successToastr('Child added to your account  succesfully.', 'success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
              this._router.navigateByUrl('/');
              return;
            }

            if (this.Userdetail != undefined && this.Userdetail != null) {
              this.disableDetailsForm = false;
              if (this.Userdetail.lastName == undefined || this.Userdetail.lastName == null) {
                this.Userdetail.lastName = '';
              }
              if (this.Userdetail.userPassword == undefined || this.Userdetail.userPassword == null) {
                this.Userdetail.userPassword = '';
              }
              //this.shiftSlide(enableType);
              // $('#divStep1').collapse('close');

              //$('#lstName').val(""); $('#pwd').val("");
              //$('#registerWizard').carousel('next');
              if (this.Userdetail.relationName == undefined || this.Userdetail.relationName == null || this.Userdetail.relationName == '') {
                this.Userdetail.relationName = '0';
              }
              console.log(this.Userdetail);
              if (this.Userdetail.sectionId != undefined && this.Userdetail.sectionId != null && this.Userdetail.SectionId != '' && this.Userdetail.sectionId != 0) {
                this.classSectionId = this.Userdetail.classId + '|' + this.Userdetail.sectionId;
              }
              else {
                this.classSectionId = "0";
              }
              setTimeout(() => {
                $('#divStep2').collapse('show');
                this.disableDetailsForm = true;
              }, 1000);
            }
            if (this.Fulldetail.length > 1) {
              this.schooldetail = this.Fulldetail[1];
            }
            if (this.Fulldetail.length > 2) {
              this.sectiondata = this.Fulldetail[2];
            }
          }

        }

      }
    }
    else {
      this.toastr.errorToastr('Photo Claim Code is wrong. Please contact support team.(support@klassakt.net)', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }

  }
  async register() {
    var isValid = true;
    $('.parentForm').each(function () {
      if ($.trim($(this).val()) == '') {
        isValid = false;
        $(this).css({
          "background": "#FFCECE",
          "border": "1px solid red"

        });
      }
      else {
        $(this).css({
          "border": "",
          "background": ""
        });
      }
    });

    // this code declear for Social registeration
    if (this.SocialIdShow == true || this.providerShow == true) {
      this.Userdetail.PrimaryEmail = this.emailId;
      this.Userdetail.firstName = this.firstName;
      this.Userdetail.lastName = this.lastName;
      this.Userdetail.userPassword = this.userPassword;
      this.Userdetail.conformPassword = this.conformPassword;
      this.Userdetail.SocialProvider = this.SocialProvider;
      this.Userdetail.SocialId = this.SocialId;
      
    }

////////////////////////////////////////////->>>>>>>
    this.Userdetail.qrCode = this.qrCode;
    if (this.Userdetail) {
      if (this.Userdetail.doSchoolEvent) {
        if (this.classSectionId == undefined || this.classSectionId == null || this.classSectionId == '' || this.classSectionId == '0') {
          this.toastr.errorToastr('Please select Class & section.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
        if (this.Userdetail.studentName == undefined || this.Userdetail.studentName == null || this.Userdetail.studentName == '') {
          this.toastr.errorToastr('Please Enter student name.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
      }
    }
    else {
      this.toastr.errorToastr('Student not avail for given student.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    if (this.Userdetail.firstName == undefined || this.Userdetail.firstName == null || this.Userdetail.firstName == '') {
      this.toastr.errorToastr('Please enter First Name.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    //if (this.Userdetail.lastName == undefined || this.Userdetail.lastName == null || this.Userdetail.lastName == '') {
    //  this.toastr.errorToastr('Please enter Last Name.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    //  return;
    //}
    if (this.Userdetail.userPassword == undefined || this.Userdetail.userPassword == null || this.Userdetail.userPassword == '') {
      this.toastr.errorToastr('Please enter Password.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    if (this.Userdetail.conformPassword == undefined || this.Userdetail.conformPassword == null || this.Userdetail.conformPassword == '') {
      this.toastr.errorToastr('Please enter Confirm Password.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    if (this.Userdetail.userPassword !== this.Userdetail.conformPassword) {
      this.toastr.errorToastr('password and Confirm password must be same.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    if (this.Userdetail.relationName == "0") {
      this.toastr.errorToastr('Please select the relation.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    //if (this.Userdetail.primaryContactNo == undefined || this.Userdetail.primaryContactNo == null || this.Userdetail.primaryContactNo == '') {
    //  this.toastr.errorToastr('Please enter contact number.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    //  return;
    //}
    if (!this.termsAndConditionSchool) {
      this.toastr.errorToastr('Please read  Terms & Condition.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    //if (!this.PasswordSavedSchool) {
    //  this.toastr.errorToastr('Please allow to create the passwod.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    //  return;
    //}if (this.Userdetail) {
    if (this.Userdetail.doSchoolEvent) {
      var classId = 0;
      var secId = 0;
      if (this.classSectionId != undefined && this.classSectionId != '') {
        let toArray = this.classSectionId.split('|');
        if (toArray != null && toArray != undefined && toArray.length == 2) {
          classId = +toArray[0];
          secId = +toArray[1];
        }
      }
      var res = await this._apiService.post('api/Register/DoCorrectDetail', { mailId: this.emailId, QrCode: this.qrCode, studentName: this.Userdetail.studentName, classId: classId, sectionId: secId, schoolId: this.Userdetail.schoolId });
      var datastr = res.toString();

      if (datastr == '') {
        this.toastr.errorToastr('Please provide the child information as registered is school.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return;
      }
      else if (datastr == "not matching") {
        this.toastr.errorToastr('Child information provided is not matching as per school records. Contact support@klassAkt.net for assistance.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return
      }
      else if (datastr.split('?').length > 1 && datastr.split('?')[1] == 'yes') {
        this.toastr.errorToastr('You are already registered for this student..', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        return;
      }
      else {
        this.Userdetail.relatedTo = +datastr;
        this.newlyRegister();
      }
    }
    else {
      this.Userdetail.relatedTo = this.Userdetail.studentId;
      this.newlyRegister();
    }

  }
  async newlyRegister() {
   
    var socialProvider = $("#socialPro").val();
    if (socialProvider == "google" || socialProvider == "facebook") {

      this.Userdetail.Password = this.userPassword;
      this.Password = this.userPassword;
      this.Userdetail.qrCode = this.qrCode;
      this.Userdetail.emailId = this.emailId;
      this.Userdetail.SocialId = this.SocialId

      this.Userdetail.SocialProvider = this.SocialProvider;

      this.Userdetail.firstName = this.firstName;
      this.Userdetail.lastName = this.lastName;

      var res = await this._apiService.post('api/Register/RegisterParentAsync', this.Userdetail);

    }
    else {
      this.Password = this.Userdetail.userPassword;
      if ((this.Userdetail.schoolId == undefined || this.Userdetail.schoolId == '' || this.Userdetail.schoolId == 0) &&
        this.schooldetail != undefined && this.schooldetail.schoolId != null &&
        this.schooldetail.schoolId != undefined && this.schooldetail.schoolId != '' && this.schooldetail.schoolId != 0)
        this.Userdetail.schoolId = this.schooldetail.schoolId;
      this.Userdetail.qrCode = this.qrCode;

      var res = await this._apiService.post('api/Register/RegisterParentAsync', this.Userdetail);
    }
    if (!res || res == undefined || res == null) {

      this.toastr.errorToastr('Data not saved, please try again.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      //this._router.navigateByUrl('/');
      return;
    }
    var regstr = res.toString();

    if (regstr == "NotSaved") {

      this.toastr.errorToastr('Data not saved, please try again.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      this._router.navigateByUrl('/');
    }
    else if (regstr == "LoginAndCheck") {

      this.toastr.successToastr('Dear ' + this.Userdetail.firstName + ', we have sent a welcome email to ' + this.Userdetail.PrimaryEmail + ' with your QR code and registration details. Feel free to write to us at support@klassakt.net for any concerns.', 'success!', { timeOut: 100000, position: 'top-full-width1', showCloseButton: true });
      this._apiService.login(this.emailId, this.Password)
        .toPromise().then(
          async () => {
            if (this._userService.isLoggedIn) {
              setTimeout(() => { this._router.navigateByUrl('/dashboard') }, 250);
            }
          })

    }
    else if (regstr == "Saved") {
      this.toastr.successToastr('Dear ' + this.Userdetail.firstName + ', we have sent a welcome email to ' + this.Userdetail.PrimaryEmail + ' with your QR code and registration details. Feel free to write to us at support@klassakt.net for any concerns.', 'success!', { timeOut: 100000, position: 'top-full-width1', showCloseButton: true });
      this._apiService.login(this.emailId, this.Password)
        .toPromise().then(
          async () => {
            if (this._userService.isLoggedIn) {
              //this._storageService.setItem('emailId', this.emailId);
              setTimeout(() => { this._router.navigateByUrl('/dashboard') }, 250);
            }
          })
    }
    //this._router.navigateByUrl('./dashboard');

  }

  private sub: any;
  async ngOnInit() {
    
    //this._userService.sessionOut();
    this.sub = this._route.queryParams.subscribe(async params => {
      let qrCode = params['qr'];
      if (qrCode != undefined && qrCode != null && qrCode.trim() != "") {
        this.qrCode = qrCode;
      }

    });
  }

  async CheckNameValidation() {
    if (!this.disableDetailsForm) return;
    if (/^[a-zA-Z ]*$/.test(this.Userdetail.firstName) == false) {
      this.toastr.errorToastr("" + this.Userdetail.firstName + " " + "should not contain Numerics and Special characters ", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.Userdetail.firstName = "";
      return false;
    }
  }
  async ChecklastNameValidation() {
    if (!this.disableDetailsForm) {
      this.Userdetail.lastName = "";
      return;
    }
    if (/^[a-zA-Z ]*$/.test(this.Userdetail.lastName) == false) {
      this.toastr.errorToastr("" + this.Userdetail.lastName + " " + "should not contain Numerics and Special characters ", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.Userdetail.lastName = "";
      return false;
    }
  }
  async CheckStuNameValidation() {
    if (!this.disableDetailsForm) return;
    if (/^[a-zA-Z ]*$/.test(this.Userdetail.studentName) == false) {
      this.toastr.errorToastr("" + this.Userdetail.studentName + " " + "should not contain Numerics and Special characters ", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.Userdetail.studentName = "";
      return false;
    }
  }
  async CheckForValidation() {
    if (!this.disableDetailsForm) {
      this.Userdetail.userPassword = '';
      return;
    }
    var patt = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9\w~@@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,16})$/;
    if (patt.test(this.Userdetail.userPassword)) { }
    else {
      this.toastr.errorToastr("Password must be of atleast 8 characters with 1 number, 1 lowercase and 1 uppercase.", 'Error', { timeOut: 0, position: 'top-full-width1', showCloseButton: true });
      this.Userdetail.userPassword = "";
      return false;
    }
  }

  reset() {
    setTimeout(() => { this._router.navigateByUrl('/pages/register') }, 10);
    this._router.navigateByUrl('/');

  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  public openterms() {
    $("#termsandcond").modal('show');
  }
  public privacypolicy() {
    $("#privacypolicy").modal('show');
  }

}
