import { Component, OnInit, Injectable, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, KlassaktLoaderService } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { KlassaktStaticPagesComponent } from '../staticPages/staticPages.component';
import { AbstractControl, ValidationErrors, ValidatorFn, Validator } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'klassakt-account',
  templateUrl: './account.component.html'
})

export class KlassaktAccountComponent extends KlassaktDashboardComponent implements OnInit{

  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }

 
  async ngOnInit() {
    this.account.highlightStudent = true;
    this.account.isheartPink = false;
    if (this.account && (this.account.relation == "" || this.account.relation == undefined || this.account.relation == null)) {     
      this.account.relation = "0";
     
      this.ishearts();
    }
  }

  public async IsFormValid()
  {
    if (!this.account.relation || this.account.relation == "0")
    {
      this.toastr.errorToastr('Please select the relation.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    var data = await this._apiService.post('api/updateParentDetails', this.account );
    if (data)
      this.toastr.successToastr('Details updated Successfully', 'success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    else
      this.toastr.errorToastr('Details Could not Update.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
  }
}

