import { Component, OnDestroy, OnInit, Input, HostListener, Pipe, PipeTransform } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { Observable, observable, from } from "rxjs";
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { Http } from '@angular/http';
import { KlassaktDashboardService, Account, listData, Student, SupportDetail, StudentDetailToAdd, paymentDetails } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forEach } from '@angular/router/src/utils/collection';
import { retry, debounce } from 'rxjs/operators';

declare var $: any;

export class KlassaktDashboardComponent implements OnInit {
  @Input()
  fullNameLength: number;
  totalSecondd: any;
  corselimgcount: number = 0;
  isCollapsed = true;
  tempcount: number = 0;
  previousval: number = 0;
  //displayText = 'height-auto';
  visible = true;
  deviceInfo: any = null;
  //viewShortlistedCount1: number = 0; //= this.account.selectedStudent.favImageWithoutCorselCount;


  status: boolean = false;
  public toggle() {
    this.visible = !this.visible;
    //this.displayText = this.visible ? 'height-auto' : 'min-h';
    this.status = !this.status;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 80) {
      let element = document.getElementById('header');
      element.classList.add('fixed');
    } else {
      let element = document.getElementById('header');
      element.classList.remove('fixed');
    }
    if (window.pageYOffset > 250) {
      let element = document.getElementById('header');
      element.classList.add('in-view');
    } else {
      let element = document.getElementById('header');
      element.classList.remove('in-view');
    }
  }


  protected curDate: any;
  public studentimage: any;
  studentName: any;
  cartid: any;
  quantity: any;
  userId: any;
  requiredImages: any;
  selectedImages: any;
  availableImages: any;
  isQrinvalid: boolean = false;
  tempDpImage: string = '//res.cloudinary.com/klassaktcontent/image/upload/w_150,h_150,c_fill,g_face,c_thumb,r_max/v1538151623/user_man.svg';
  productID: any;
  schoolpackgID: any;
  countImageswithoutCousel: number = 0;
  NonCourselImageCount: number = 0;
  iPhoneVer: any;
  isipad: any;
 

  public isIPhone: boolean = false;
  searchstring: any;

  public checkScreenSize() {

    this.isIPhone = true;
    this.isipad = true;

  }


  @Input()
  public isHeartsOnly: boolean = false;

  public isheartpink: boolean = false;
  private instance: KlassaktDashboardComponent;
  private static _tokenSubscription: any;
  public static _isDashboardLoaded: number = 0;
  protected static _selectedimageid: string = '';

  //photos: Student;


  public constructor(

    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer
    //public deviceService:DeviceDetectorService
    ) {
    this.instance = this;
    //this.epicFunction();


  }

  public async ngOnInit() {

    if (this._userService.isLoggedIn && this._userService.data && this._userService.data.id &&
      this.account && this.account.userId &&
      this.account.userId.toString() != this._userService.data.id) {
      this.ishearts();
     
      window.location.reload(true); /* reload entire page when the user login with different user id*/

    }
    if (navigator.appVersion.indexOf("iPhone") != -1 || navigator.appVersion.indexOf("iPad") != -1) {

      this.checkScreenSize();
      if (this.isIPhone && this.isipad) {
        this.iPhoneVer = "' To download this photo on  your iPhone or iPad , please press your finger down on the photo and hold it till you see the save photo option’";


      }

    }
    if (KlassaktDashboardComponent._isDashboardLoaded == 0 && this._userService.isLoggedIn) {
      KlassaktDashboardComponent._isDashboardLoaded = KlassaktDashboardComponent._isDashboardLoaded + 1;
      if (KlassaktDashboardComponent._isDashboardLoaded <= 1 && !this.account || this.account == null) {
        if (!KlassaktDashboardComponent._tokenSubscription) {
          if (KlassaktDashboardComponent._tokenSubscription == undefined && KlassaktDashboardComponent._isDashboardLoaded <= 1) {
            KlassaktDashboardComponent._tokenSubscription = this._apiService.tokenReceived.subscribe(async (data) => this.reloadAccount(data));

          }
        }


        await this.loadAccountDetails();
        
      }
    }

    this.callStudentsLoaded();

    var OSName = "Unknown OS";
    $(document).ready(function () {
      var height = $('#header').innerHeight();
      $('.topmenusbg').css('margin-top', height);

      if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
      if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
      if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
      if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

    });

    

  }

  public async search() {
    var id = $("#searchbyqrcode").val();
    //  id = id.trim();
    this.selectedStudent.photos = await this._apiService.post<listData<any>>('api/GetQrcodeImages', { studentdet: this.selectedStudent, searchstring: id, studentlist: this.account.students });
    this.setCountDown();
    this.setMasonry();
  }
  //epicFunction() {

  //  this.deviceInfo = this.deviceService.getDeviceInfo();
  //  const isMobile = this.deviceService.isMobile();
  //  const isTablet = this.deviceService.isTablet();
  //  const isDesktopDevice = this.deviceService.isDesktop();
  //  console.log("Hello Arjun This is all device", this.deviceInfo);
  //  console.log("Hello Arjun This is for mobile", isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
  //  console.log(isTablet);  // returns if the device us a tablet (iPad etc)
  //  console.log("Hello Arjun This is for PC", isDesktopDevice); // returns if the app is running on a Desktop browser.
  //}
   
  public ClearSearch() {

    var id = $("#searchbyqrcode").val('');
    this.setMasonry();
    this.search();
    this.setCountDown();

  }

  protected async onVisible() {
  }
  protected async onStudentChanged() { }
  protected async onHide() { }
  protected async onAfterStudentsLoaded() {

  }
  public getStudentwithoutCoursel() {
    let imageshertedCount: number = 0;
    let imagesNonhertedCount: number = 0;
    if (this.account && this.account.students) {
      for (let studTemp of this.account.students) {
        if (studTemp.photos && studTemp.photos.list && studTemp.photos.list.length > 0) {
          for (let p of studTemp.photos.list) {
            if (!p.isCarouselImage) {
              if (p.isHearted) {
                imageshertedCount++;
              }
              imagesNonhertedCount++;
            }
          }
        }
        studTemp.favImageWithoutCorselCount = imageshertedCount;
        studTemp.NonfavImageWithoutCorselCount = imagesNonhertedCount;
        this.ishearts();
       
      }
    }
  }
  public getStudentwithoutCourselSelectedStud() {
    let imageshertedCount: number = 0;
    let imagesNonhertedCount: number = 0;

    if (!this.isHeartsOnly) {

      if (this._dashboardService.selectedStudent.photos && this._dashboardService.selectedStudent.photos.list && this._dashboardService.selectedStudent.photos.list.length > 0) {
        for (let p of this._dashboardService.selectedStudent.photos.list) {
          if (!p.isCarouselImage) {
            if (p.isHearted) {
              imageshertedCount++;

            }
            imagesNonhertedCount++;
          }
        }
      }
      this._dashboardService.selectedStudent.favImageWithoutCorselCount = imageshertedCount;
      this._dashboardService.selectedStudent.NonfavImageWithoutCorselCount = imagesNonhertedCount;
      this.ishearts();
     
      this.account.highlightStudent = false;
    }
  }
  public get DashboardSectionInstance(): any {
    return this._dashboardService.DashboardSection;
  }
  public setMasonry() {
    setTimeout(() => {
      //$('#grid').queue('items', function (next) {
      let grid = $('.grid').imagesLoaded(function () {
        if (grid && grid.length > 0) {
          grid.masonry().masonry('layout');
          grid.masonry().masonry('destroy');
          grid.masonry().masonry({
            itemSelector: '.grid-item',
            percentPosition: true,
            horizontalOrder: true,
            columnWidth: '.grid-sizer'
          });
        }

      });
    }, 150);
  }

  public setStudent(stud: Student): void {

    this.account.highlightStudent = true;
    this.account.isheartPink = false;
    this._dashboardService.selectedStudent = stud;
    this.gotoDashboard();

    setTimeout(async () => {
      if (this.DashboardSectionInstance && this.DashboardSectionInstance.selectedTabInstance && this.DashboardSectionInstance.selectedTabInstance.onVisible) {
        await this.DashboardSectionInstance.selectedTabInstance.onVisible();
      }
    }, 10);
    this.setCountDown();
    this.ishearts();
    this.ClearSearch();
    this.setMasonry();
    // this.search();
    this.setMasonry();
  }
  public static clock: any;
  private statics = KlassaktDashboardComponent;
  protected setCountDown() {
    if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0 && this.selectedStudent.photos.list[0].timerEndDate) {
      let dte = new Date(this.selectedStudent.photos.list[0].timerEndDate);
      var diffMs = (dte.getTime() - new Date().getTime());
      //this.selectedStudent.timeStamp = ((diffMs / 1000 * 60) / 1000);
      this.selectedStudent.timeStamp = (diffMs / 1000 );
      this.statics.clock = $('.clock').FlipClock({
        clockFace: 'DailyCounter',
        autoStart: false,
      });
      this.statics.clock.setTime(this.selectedStudent.timeStamp);
      this.statics.clock.setCountdown(true);
      this.statics.clock.start();
    }
    else {

      this.statics.clock = $('.clock').FlipClock({
        clockFace: 'DailyCounter',
        autoStart: false,
      });
      this.statics.clock.setTime(0.00);
      this.statics.clock.setCountdown(true);
      this.statics.clock.stop();
    }
  }
  public goToSelectedStudent(): void {
    this.account.highlightStudent = true;
    this.account.isheartPink = true;
    this.ishearts();
    this.ClearSearch();
    this.gotoDashboard();

    setTimeout(async () => {
      if (this.DashboardSectionInstance && this.DashboardSectionInstance.selectedTabInstance && this.DashboardSectionInstance.selectedTabInstance.onVisible) {
        await this.DashboardSectionInstance.selectedTabInstance.onVisible();
      }
    }, 100);
  }


  public async hearted(p, typeID, refID) {

    p.isHearted = !p.isHearted;

    let result = await this._apiService.post<boolean>('api/AddFavourite', { studentUserID: this.selectedStudent.userID, parentUserID: this._userService.data.id, refID: refID, typeID: typeID });
    if (!result) {
      this.toastr.successToastr('There was some error', 'Error!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      p.isHearted = !p.isHearted;
    }

    if ($('div#homeClassPhotoCarousel.carousel-item.active').length == 0) {
      $('div#homeClassPhotoCarousel .carousel-item:first').addClass('active');
    }
    this.ishearts();
    return result;

  }

  public ishearts(): boolean {
    var res = undefined;
    if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0) {
      for (let p of this.selectedStudent.photos.list) {
        if (p.isHearted) 
        {
          res = p.isHearted;
          break;
        }
        else 
        {
          res = false;
        }
      }
    }

    if (res != undefined) {
      this.account.isheartPink = res;

    }
    return res;
  }

  public async reloadAccount(data) {
    KlassaktDashboardComponent._isDashboardLoaded = 0;
    this._dashboardService.account = null;
    await this.ngOnInit();
  }
  private async loadAccountDetails() {

    this._dashboardService.account = await this._apiService.post<Account>('api/getAccountDetails', { AccountID: this._userService.data.id });
    this.getStudentwithoutCoursel();
    this.account.paymentDetails = new paymentDetails();
    this._dashboardService.selectedStudent = this.account.students[0];
    this.account.highlightStudent = true;
    this.account.isheartPink = false;
    setTimeout(function() {
      $("#content-1,#content-2").mThumbnailScroller({
        type: "click-50",
        theme: "buttons-out"
      });

      $("#info a").click(function (e) {
        e.preventDefault();
        var $this = $(this),
          el = $this.attr("rel"),
          to = $this.attr("href").split("#")[1];
        if (to === "center-white") {
          to = $("#white").position().left + ($("#white").width() / 105) - ($(el).find(".mTSWrapper").width() / 105);
        } else if (to === "yellow") {
          to = $("#yellow");
        } else if (to === "by-105") {
          to = "-=105";
        }
        $(el).mThumbnailScroller("scrollTo", to);
      });
    }, 250);

  }

  pad(n, width, z = '0') {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  public callStudentsLoaded() {
    if (!this._dashboardService.account) { /*wait for 3 seconds*/
      setTimeout(() => { this.callStudentsLoaded(); }, 250);
      return;
    }
    setTimeout(() => { this.onAfterStudentsLoaded(); }, 100);
  }

  public closeAddKid() {
    this.addNewStdObj = new StudentDetailToAdd();
    $("#AddNewKid").modal('hide');
  }

  public addNewStdObj: any = {};
  public getting: any = {};

  public AddNewKidPopup() {
    $("#AddNewKid").modal('show');
  }
  public async getStudentDetail() {
    
    if (this.addNewStdObj.qrCode != "" && this.addNewStdObj.qrCode != null) {
      let result = await this._apiService.post<string>('api/support/doQrExists', this.addNewStdObj.qrCode);
      if (result == "true") {
        this.addNewStdObj = await this._apiService.post<StudentDetailToAdd>('api/support/getStudentDetail', { QrNo: this.addNewStdObj.qrCode, parentId: this._userService.data.id });

        if (this.addNewStdObj.doAlreadyRegistered == "true") {
          this.toastr.warningToastr('You are already registered for this kid.', 'warning', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
          this.isQrinvalid = true;
          let stdObj = await this.getStudent(parseInt(this.addNewStdObj.studentID));
          this.setStudent(stdObj);
          this.ishearts();
          this.closeAddKid();
        }
        else if (this.addNewStdObj.doAlreadyRegistered == "IncorrectSchool") {
          this.toastr.errorToastr('This account is already used for a different School. Kindly create a new account with a different email id to add this student.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
        else if (this.addNewStdObj.doAlreadyRegistered == "notFound") {
          this.toastr.errorToastr('Student detail not found.', 'error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
          return;
        }
        else {
          this.isQrinvalid = false;
        }

      }

      else {
        this.toastr.errorToastr('Photo Claim Code is wrong. Please contact support team.(support@klassakt.net)', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        this.isQrinvalid = true;
        this.addNewStdObj.parentName = "";
        this.addNewStdObj.relation = "";
        this.addNewStdObj.studentName = "";
        this.addNewStdObj.schoolName = "";
        this.addNewStdObj.selectedSectionId.sectionId = "";
        this.addNewStdObj.studentNameToEnter = "";
      }

    } else {
      this.toastr.errorToastr('Please enter Photo Claim Code.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
      this.isQrinvalid = true;
    }

  }

  public async AddKidToParent() {
    if (this.addNewStdObj.qrCode == "" || this.addNewStdObj.qrCode == undefined || this.addNewStdObj.qrCode == null) {
      this.toastr.errorToastr('Please enter QRCode.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
      return false;
    }
    else if (this.addNewStdObj.parentName == "" || this.addNewStdObj.parentName == undefined || this.addNewStdObj.parentName == null) {
      this.toastr.errorToastr('Parent Name is required.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
      return false;
    }

    else if (this.addNewStdObj.relation == "" || this.addNewStdObj.relation == undefined || this.addNewStdObj.relation == null) {
      this.toastr.errorToastr('Please select the Relation.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
      return false;
    }

    else if ((this.addNewStdObj.studentNameToEnter == "" || this.addNewStdObj.studentNameToEnter == undefined || this.addNewStdObj.studentNameToEnter == null)
      && (this.addNewStdObj.studentName == "" || this.addNewStdObj.studentName == undefined || this.addNewStdObj.studentName == null)) {
      this.toastr.errorToastr('Student Name is required.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
      return false;
    }

    if (this.addNewStdObj.studentNameToEnter) {
      this.getting = await this._apiService.post<StudentDetailToAdd>('api/support/DoCorrectStudent', {

        stdName: this.addNewStdObj.studentNameToEnter,
        parentId: this._userService.data.id,
        secId: this.addNewStdObj.selectedSectionId.sectionId
      })
      if (this.getting.doAlreadyRegistered == "not matching") {
        this.toastr.errorToastr('Please enter student name as registered in school.', 'error', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        this.addNewStdObj.studentNameToEnter = "";
        return;
      }
      if (this.getting.doAlreadyRegistered == "true") {
        this.toastr.warningToastr('You are already registered for this kids.', 'warning', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        this.closeAddKid();
      }
      else if (this.getting.doAlreadyRegistered == "yes") {
        this.toastr.warningToastr('You are already registered for this kid.', 'warning', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        let stdObj1 = await this.getStudent(parseInt(this.getting.studentID));
        this.setStudent(stdObj1);
        this.ishearts();
        this.closeAddKid();

      } else {
        this.getting = await this._apiService.post<StudentDetailToAdd>('api/support/addStudent', {
          QrNo: this.addNewStdObj.qrCode,
          parentId: this._userService.data.id,
          stdId: parseInt(this.getting.studentID),
          sectionId: parseInt(this.addNewStdObj.selectedSectionId.sectionId),
          classId: parseInt(this.addNewStdObj.selectedSectionId.classId),
          schoolId: parseInt(this.addNewStdObj.schoolId),

        });
        this.toastr.successToastr('Registered Successfully.', 'success', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        await this.loadAccountDetails();
        this.ishearts();
        this.closeAddKid();
      }
    }
    else {
      this.getting = await this._apiService.post<StudentDetailToAdd>('api/support/addStudent', { QrNo: this.addNewStdObj.qrCode, parentId: this._userService.data.id, });
      if (this.getting.doAlreadyRegistered == "true") {
        this.toastr.warningToastr('You are already registered for this kid.', 'warning', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true })
        let stdObj3 = await this.getStudent(parseInt(this.getting.studentID));
        this.setStudent(stdObj3);
        this.ishearts();
       
        this.closeAddKid();

      } else {
        this.toastr.successToastr('Registered Successfully.', 'success', { timeOut: 30000, position: 'top-full-width1', showCloseButton: true });
        await this.loadAccountDetails();
        this.ishearts();
       
        this.closeAddKid();
      }
    }
  }

  public goToPage(page) {
    this._router.navigateByUrl(page);
  }

  public get singleStudent(): Student {
    var studentId = this._dashboardService.selectedStudent.userID;
    for (let i in this._dashboardService.account.students) {
      //return this._dashboardService.selectedStudent((a, b) => (a.userID > b.userID) ? 1 : ((b.userID > a.userID) ? -1 : 0));
      if (this._dashboardService.account.students[i].userID == studentId)
        return this._dashboardService.account.students[i]
    }
  }

  public get selectedStudent(): Student {
    return this._dashboardService.selectedStudent;
  }
  public get watermarkImage(): Account {
    return this._dashboardService.waterMarkUrl;
  }
  public get ishearted(): Account {
    return this._dashboardService.isheartpink;
  }
  //public get SearchQrcode(): boolean {
  //  return this._dashboardService.account.SearchQrcode;
  //}

  public get dashboardService(): KlassaktDashboardService {
    return this._dashboardService;
  }

  public get account(): Account {
    return this._dashboardService.account;
  }

  public get students(): Student[] {
   
    return this._dashboardService.account.students;
  
  }

  public get isLoading(): boolean {
    return this._apiService.isLoading;
  }




  public gotoDashboard() {
    //this.selectedTabName = 'Photos';
    this.ishearts();
    
    this.account.isheartPink = false;
    this.setMasonry();
    this._router.navigateByUrl('/dashboard');
  }

  protected getStudent(studentId: number): Student {
    for (let i in this.students) {
      if (this.students[i].userID == studentId)
        return this.students[i];
    }

  }

  public getStudentFaceImage(img: string): string {
    return img == undefined ? "" : img.replace('/image/upload/', '/image/upload/w_100,h_100,c_fill,g_face,c_thumb,r_max/').replace('http:', '');
  }
  async addPackageCarts(packageID: string) {

    var data = await this._apiService.post('api/addproductcart', { userID: this.selectedStudent.userID, ProductId: '00000000-0000-0000-0000-000000000000', packageID: packageID, parentUserID: this._userService.data.id })
    this.toastr.successToastr('Item Successfully Added To Cart', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    this.goToPage('/dashboard/(dashboardRouter:viewcart)');

  }
  async addProductsCarts(ProductId: string, productName: string) {
    {
      (<any>window).ga('send', 'event', {
        eventCategory: 'Product',
        eventLabel: 'Product',
        eventAction: 'Add to Cart' + "-->" + productName + "",
        eventValue: 10
      });   
      console.log(productName);
      var data = await this._apiService.post('api/addproductcart', { userID: this.selectedStudent.userID, ProductId: ProductId, packageID: '00000000-0000-0000-0000-000000000000', parentUserID: this._userService.data.id })
      this.toastr.successToastr('Item Successfully Added To Cart', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      this.goToPage('/dashboard/(dashboardRouter:viewcart)');
    }
  }
  // Start View and Images by Arshad on 27072021

  //public getImage(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, width: number = 510, waterMarkUrl: string): string {
  //  // var replacingString: string = '$n_ih,a_' + angle + ',w_ar_mul_' + width;
  //  // var replacingString: string = '$n_ih,a_' + angle;
  //  //  if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_aor4d5,h_$n_div_1.5';  w_310,a_0/l_aor4d5,w_200
  //  //$n_ar, a_0, w_310 / l_aor4d5, if_$n_gt_1, w_310_div_$n / l_aor4d5, if_else, w_310_div_1.5
  //  var replacingString: string = '$n_ar,$wd_' + width + ',w_$wd,a_' + angle;
  //  if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',if_$n_gt_1,w_$wd_div_$n/l_' + waterMarkUrl + ',if_else,w_$wd_div_1.5';
  //  return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');

  //}

  public getImage(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, width: number = 510, waterMarkUrl: string, klassaktImageID: string): string {
    var replacingString: string = '$n_ar,$wd_' + width + ',w_$wd,a_' + angle;
    if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',if_$n_gt_1,w_$wd_div_$n/l_' + waterMarkUrl + ',if_else,w_$wd_div_1.5';
    var allowedExtensions = /(\.mp4)$/i;
    if (!allowedExtensions.exec(img)) {
      return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');
    }
    else {
      $('#divimg_' + klassaktImageID).hide();
      return img = '';
    }
  }
  public getVideo(img: string, klassaktImageID: string): string {
    var allowedExtensions = /(\.mp4)$/i;
    if (allowedExtensions.exec(img)) {
      return img.replace('http:', '');
    }
    else {
      $('#video_' + klassaktImageID).hide();
      return img = '';
    }
  }

 //End View and Images by Arshad on 27072021
  public getImageForFlipBook(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, width: number = 510, waterMarkUrl: string): string {
    var replacingString: string = '$n_ar,$wd_' + width + ',w_$wd,a_' + angle; 
    return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');
  }



  public getDownloadImage(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, width: number = 510): string {

    var replacingString: string = 'a_' + angle;
    return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');

  }

  //Start View and Images by Arshad on 27072021

  //public getGalleryImages(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, height: number = 400, waterMarkUrl: string): string {
  //  var replacingString: string = '$n_ar,$ht_' + height + ',h_$ht,a_' + angle;
  //  if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',w_$ht_div_1.5';

  //  return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');

  //}

  public getGalleryImages(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, height: number = 400, waterMarkUrl: string, klassaktImageID: string): string {
    var replacingString: string = '$n_ar,$ht_' + height + ',h_$ht,a_' + angle;
    if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',w_$ht_div_1.5';
    var allowedExtensions = /(\.mp4)$/i;
    if (!allowedExtensions.exec(img)) {
      return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');
    }
    else {
      $('#divimg_' + klassaktImageID).hide();
      return img = '';
    }

  }
  public getGalleryVideo(img: string, klassaktImageID: string): string {
    var allowedExtensions = /(\.mp4)$/i;
    if (allowedExtensions.exec(img)) {
      return img.replace('http:', '');
    }
    else {
      $('#video_' + klassaktImageID).hide();
      return img = '';
    }
  }

  public slctgetGalleryImages(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, height: number = 400, waterMarkUrl: string, klassaktImageID: string): string {
    var replacingString: string = '$n_ar,$ht_' + height + ',h_$ht,a_' + angle;
    if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',w_$ht_div_1.5';
    var allowedExtensions = /(\.mp4)$/i;
    if (!allowedExtensions.exec(img)) {
      return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');
    }
    else {
      $('#slctdivimg_' + klassaktImageID).hide();
      return img = '';
    }

  }
  public slctgetGalleryVideo(img: string, klassaktImageID: string): string {
    var allowedExtensions = /(\.mp4)$/i;
    if (allowedExtensions.exec(img)) {
      return img.replace('http:', '');
    }
    else {
      $('#slctvideo_' + klassaktImageID).hide();
      return img = '';
    }
  }


  public glrygetGalleryImages(iswatermarkreq: string, img: string, angle: string, isWaterMark: boolean, height: number = 400, waterMarkUrl: string, klassaktImageID: string): string {
    var replacingString: string = '$n_ar,$ht_' + height + ',h_$ht,a_' + angle;
    if (iswatermarkreq == "1" && isWaterMark) replacingString = replacingString + '/l_' + waterMarkUrl + ',w_$ht_div_1.5';
    var allowedExtensions = /(\.mp4)$/i;
    if (!allowedExtensions.exec(img)) {
      return img.replace('/image/upload/', '/image/upload/' + replacingString + '/').replace('http:', '');
    }
    else {
      $('#glrydivimg_' + klassaktImageID).hide();
      return img = '';
    }

  }
  public glrygetGalleryVideo(img: string, klassaktImageID: string): string {
    var allowedExtensions = /(\.mp4)$/i;
    if (allowedExtensions.exec(img)) {
      return img.replace('http:', '');
    }
    else {
      $('#glryvideo_' + klassaktImageID).hide();
      return img = '';
    }
  }

 //End View and Images by Arshad on 27072021

  public getStudentcartImage(img: string, angle: string, height: number): string {
    return img.replace('/image/upload/', '/image/upload/h_' + height + ',a_' + angle + '/');
  }

  public setImage(img) {
    this.studentimage = img;
    $("#studentphoto").modal('show');
  }
  public getimgcount(cnt) {
    let arr = [];
    for (let i = 1; i <= cnt; i++) {
      arr.push(i);
    }
    return arr;
  }

  //selectedProd: any;

  public setdeleteConfirm(userId, cartID) {
    {
      (<any>window).ga('send', 'event', {
        eventCategory: 'ViewCart',
        eventLabel: 'ViewCart',
        eventAction: 'Remove/Empty Cart',
        eventValue: 10
      });
    }
    this.cartid = cartID;
    this.userId = userId;
    $("#confirmDelete").modal('show');

  }
  Productishearted(heartCheck) {
    if (heartCheck == undefined || heartCheck != true || heartCheck == null)
      return true;
    else
      return false;

  }

  public get isCartempty(): boolean {
    for (let i of this.students) {
      if (i.cart && i.cart.productDetail && i.cart.schoolPackages && (i.cart.productDetail.length + i.cart.schoolPackages.length) > 0) {
        return true;
      }
    }
    return false;
  }

  public logOut() {
    this._userService.logout();
    this._router.navigate(['/']);
  }


  public gotoSupport() {
    this._router.navigateByUrl('/dashboard/(dashboardRouter:support)');
  }
  public changePassword() {
    this._router.navigateByUrl('/dashboard/(dashboardRouter:changePassword)');
  }


  public setSelectedImage(imageobject) {
    var isdelete = false;
    this.selectedImages.forEach((item, index) => {
      if (item.klassaktImageID == imageobject.klassaktImageID) {
        this.selectedImages.splice(index, 1);
        isdelete = true;
      }
    });
    if (isdelete) {
      for (let img of this.availableImages) {
        if (img.klassaktImageID == imageobject.klassaktImageID) {
          img.isSelected = false;
          return;
        }
      }
      this.ishearts();
    }
    if (this.selectedImages.length == this.requiredImages) {
      this.toastr.errorToastr('Please uncheck the selection first to proceed with new image choice.', 'Mandatory', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
    } else {
      this.selectedImages.push(imageobject);
      for (let img of this.availableImages) {
        if (img.klassaktImageID == imageobject.klassaktImageID) {

          img.isSelected = true;
          return;
        }
      }
    }
  }
  public removeSelectedImage(imageobject) {
    this.account.isheartPink = true;
    for (let img of this.availableImages) {
      if (img.klassaktImageID == imageobject.klassaktImageID) {
        img.isSelected = false;
        this.selectedImages.splice($.inArray(imageobject, this.selectedImages), 1);
        return;
      }
    }
  }
  public async getPreview(name: string, ID: number, cartID: string, productID: string, noOfimages: number, schoolpackgID: string) {
    let previewimages: any;
    this.userId = ID;
    this.studentName = name;
    this.requiredImages = noOfimages;
    this.cartid = cartID;
    this.productID = productID;
    this.schoolpackgID = schoolpackgID;
    this.NonCourselImageCount = 0;
    let studentimages = this.getStudent(ID).photos;
    if (studentimages != null && studentimages.list.length > 0) {
      previewimages = await this._apiService.post('api/getPreviewDetails', { userID: ID, parentUserID: this._userService.data.id, cartID: cartID, ProductId: productID, StudentImages: studentimages });
      $("#PreviewModal").modal('show');
      if (previewimages.klassActImage && previewimages.klassActImage.length != 0) {
        this.availableImages = previewimages.klassActImage;
        this.selectedImages = previewimages.klassAktImageSelected;
        for (let img of this.availableImages) {
          if (!img.IsCarouselImage)
            this.NonCourselImageCount++;
        }

      } else {
        this.availableImages = null;
        this.selectedImages = null;
      }

      for (let AvailItem of this.availableImages) {
        for (let SelectedItem of this.selectedImages) {

          if (AvailItem.klassaktImageID == SelectedItem.klassaktImageID) { AvailItem.isSelected = true; }
          else {
            if (AvailItem.isSelected != true)
              AvailItem.isSelected = false;
          }
        }
      }

      this.setMasonry();
      this.ishearts();
     

    } else {
      this.toastr.errorToastr('Images are not available for Selection', 'Mandatory', { timeOut: 25000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    {
      (<any>window).ga('send', 'event', {
        eventCategory: 'ViewCart',
        eventLabel: 'ViewCart',
        eventAction: 'choose photo',
        eventValue: 10
      });
    }
  }

  //cart page
  public get iscarouselImages(): boolean {
    if (this.availableImages) {
      for (let img of this.availableImages) {
        if (img.isCarouselImage == true) {
          return true;
        }
      }

    }
    return false;
  }

  public get highlightStudent(): boolean {
    return this._dashboardService.account.highlightStudent;
  }
  public get isheartPink(): boolean {
    return this._dashboardService.account.isheartPink;
  }

  public get availablecarouselImages(): any {
    let carouselImage = [];
    if (this.availableImages) {
      for (let img of this.availableImages) {
        if (img.isCarouselImage == true) {
          carouselImage.push(img);
        }
      }

    }
    return carouselImage;

  }

  //landing page code
  public get iscarouselPhotos(): boolean {
    if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0) {
      for (let img of this.selectedStudent.photos.list) {
        if (img.isCarouselImage == true && (!this.isHeartsOnly || img.isHearted)) {
          return true;
        }
      }

    }
    return false;

  }

  public CarouselImages = [];
  public availablecarouselPhotos(): any {
    this.CarouselImages.splice(0, this.CarouselImages.length);
    if (this.selectedStudent && this.selectedStudent.photos && this.selectedStudent.photos.list && this.selectedStudent.photos.list.length > 0) {
      for (let img of this.selectedStudent.photos.list) {
        
        if (img.isCarouselImage && img.eventName != "FLIPBOOK" && (!this.isHeartsOnly || img.isHearted)) {
          this.CarouselImages.push(img);
        }
        else if (img.eventName === "FLIPBOOK") {
          console.log("Hello Arjun Walmiki You are on flip book page", img.eventName);
        }

      }
    }
    return this.CarouselImages;
  }


}



@Component({
  selector: 'Klassakt-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class KlassaktDashboardMainComponent extends KlassaktDashboardComponent implements OnInit, OnDestroy {

  @Input() Id: number;
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }

  async ngOnInit() {
    await super.ngOnInit();

  }

  ngOnDestroy() {
    // if (KlassaktDashboardComponent._tokenSubscription) KlassaktDashboardComponent._tokenSubscription.unsubscribe();
  }

  public checkIstrueCol2(initialVal, tempCountdata) {

    if (initialVal != this.previousval) {
      this.tempcount = 0;
      tempCountdata = initialVal;
      this.previousval = initialVal;
    }

    if (initialVal == tempCountdata)
      return true;
    let tempNumber: number;
    tempNumber = initialVal;
    for (let j = initialVal; j < tempCountdata && tempNumber <= tempCountdata; j++) {
      if (tempNumber == tempCountdata) {
        if ((this.isHeartsOnly && this.selectedStudent.favImageWithoutCorselCount == tempCountdata) || (!this.isHeartsOnly && this.selectedStudent.NonfavImageWithoutCorselCount == tempCountdata)) {
          this.tempcount = 0;
        }
        return true;
      }
      tempNumber += 4;
    }
    if ((this.isHeartsOnly && this.selectedStudent.favImageWithoutCorselCount == tempCountdata) || (!this.isHeartsOnly && this.selectedStudent.NonfavImageWithoutCorselCount == tempCountdata)) {
      this.tempcount = 0;
    }
    return false;

  }

  public increamentval() {

    this.tempcount += 1;
    return this.tempcount;
  }
  public valueReset() {
    this.tempcount = 0;
    return this.tempcount;
  }
  protected get tempCountval() {
    return this.tempcount;
  }
}




