import { Component, OnInit, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { Http } from '@angular/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'klassakt-orderConfirmation',
  templateUrl: './orderConfirmation.component.html'
})
export class KlassaktOrderConfirmationComponent extends KlassaktDashboardComponent implements OnInit, OnDestroy {
  public downloadInvoiceUrl: any;
 private sub: any;
  private oid: string;

  public orderList: any;
  async ngOnInit() {
    await super.ngOnInit();

    
 this.sub = this._route.params.subscribe(async params => {
   if (params['rid'] != undefined || params['rid'] != null) {
        this.oid= params['rid'];
   
      }
    });

    
      this.orderList = await this._apiService.post('api/getOrderedDetails', { StudentID: 0, AccountID: this._userService.data.id, OrderID: this.oid });
      if (this.orderList[0].orderStatus == "Invalid") {
        this.toastr.errorToastr('Order Unsuccessfull', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      } else {
        this.toastr.successToastr('Order Placed Successfully', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      }
   
    
  }

  protected async onAfterStudentsLoaded() {

    this.setCountDown();
    this.setMasonry();
   // this.account.highlightStudent = true;
   // this.account.isheartPink  =false;
    
    //this.orderList = await this._apiService.post('api/getOrderedDetails', { StudentID: 0, AccountID: this._userService.data.id, OrderID: this.oid });
    //if (this.orderList[0].orderStatus == "Invalid") {
    //  this.toastr.errorToastr('Order Unsuccessfull', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    //} else {
    //  this.toastr.successToastr('Order Placed Successfully', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    //}
    
    //console.log(this.orderList);
  }
   
  public async downloadInvoice(oid, onumber) {
    window.open('/api/InvoiceDownload/' + oid + '/' + onumber);
  }


  ngOnDestroy() {
    if(this.sub) this.sub.unsubscribe();
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
