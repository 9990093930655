import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, SupportDetail } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'klassakt-support',
  templateUrl: './support.component.html'
})

export class KlassaktSupportComponent extends KlassaktDashboardComponent implements OnInit {
  schoolid: any;
  suppDetail: any = {};
  public newSupportComment: any = {};
  
  async ngOnInit() {
    await super.ngOnInit();
    
   // console.log(this.selectedStudent.supportDetail);

  }

  protected async onAfterStudentsLoaded() {
    this.account.highlightStudent = false;
   
    this.account.isheartPink = false;
    this.SupportRequestDetailPage();
  }

  async SupportRequestDetailPage() {
    this.selectedStudent.supportDetail = await this._apiService.post<SupportDetail>('api/support/supportPage', { presentStudentUserID: this.selectedStudent.userID, parentID: this._userService.data.id });
    this.newSupportComment.contactNo = this.selectedStudent.supportDetail.contactNo;
    this.newSupportComment.mailId = this.selectedStudent.supportDetail.mailId;
  }
    

  async addSupportRequest() {
    this.newSupportComment.parentID = this._userService.data.id;
    this.newSupportComment.schoolId = this.selectedStudent.schoolId;
    this.newSupportComment.selectedStdUserID = this.selectedStudent.userID;
    let res = await this._apiService.post<string>('api/support/addSupportRequest', this.newSupportComment);
    this.toastr.successToastr('Support request raised successfully.', 'success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    this.SupportRequestDetailPage();
    this.newSupportComment.comment = '';
    this.newSupportComment.issueCategory = '';
    console.log(res);
  }

  async userReplay(reply, supNo) {
    if (reply == '' || reply == undefined || reply == null) {
      this.toastr.errorToastr('Please enter reply message.', 'Reply message empty', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
    let useRep = await this._apiService.post<string>('api/support/userReplay', { comment: reply, parentID: this._userService.data.id, suppNo: supNo });
    this.toastr.successToastr('Your reply is sent successfully.', 'success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
    this.SupportRequestDetailPage();
  }

  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
