import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'klassakt-orders',
  templateUrl: './orders.component.html'
})
export class KlassaktOrdersComponent extends KlassaktDashboardComponent implements OnInit {
  public downloadInvoiceUrl: any;

  protected async onVisible() { 
    
    if (this.account && this.selectedStudent && this.selectedStudent.orders == undefined) {

      this.selectedStudent.orders = await this._apiService.post('api/getOrderDetails', { studentID: this.selectedStudent.userID, accountID: this._userService.data.id });
     // console.log(this.selectedStudent.orders);
    }

  }

  protected async onAfterStudentsLoaded() {
    //await this.onVisible();

  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this.account && this.selectedStudent && this.selectedStudent.orders == undefined) {

      this.selectedStudent.orders = await this._apiService.post('api/getOrderDetails', { studentID: this.selectedStudent.userID, accountID: this._userService.data.id });
      // console.log(this.selectedStudent.orders);
    }
  }

  public async downloadInvoice(oid, onumber) {
    window.open('/api/InvoiceDownload/' + oid + '/' + onumber);
  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
