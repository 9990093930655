import { Routes, RouterModule } from '@angular/router';

import { KlassaktPermissionGuard, KlassaktRedirectComponent } from '../app/core';
import { KlassaktNotFoundComponent } from './not-found.component';
import {KlassaktDashboardComponent, KlassaktDashboardMainComponent} from './app-shell';
import { KlassaktDashboardSectionsComponent } from './app-shell/dashboard-sections/dashboard-sections.component';
import { KlassaktGalleryComponent } from './app-shell/gallery/gallery.component';
import { KlassaktStaticPagesComponent } from './app-shell/staticPages/staticPages.component';
import { KlassaktViewcartComponent } from './app-shell/viewcart/viewcart.component';
import { KlassaktViewcartpaymentComponent } from './app-shell/viewcartpayment/viewcartpayment.component';
import { KlassaktOrderSummaryComponent } from './app-shell/orderSummary/orderSummary.component';
import { dashboardRoute } from './app-shell/dashboard/dashboard.routes';
import { KlassaktSupportComponent } from './app-shell/support/support.component';
import { KlassaktPackageDetailsComponent } from './app-shell/packages/details/packageDetails.component';
import { KlassaktProductDetailsComponent } from './app-shell/products/details/productDetails.component';
import { KlassakChangePasswordComponent } from './app-shell/changePassword/changePassword.component';
import { KlassaktHeartsComponent } from './app-shell/hearts/hearts.component';
import { KlassaktRegisterComponent } from './app-shell/register/register.component';
import { KlassaktOrderConfirmationComponent } from './app-shell/orderConfirmation/orderConfirmation.component';
import { KlassaktForgotPasswordComponent } from './app-shell/forgotPassword/forgotPassword.component';
import { KlassaktAccountComponent } from './app-shell/account/account.component';
import { KlassaktDownloadsComponent } from './app-shell/downloads/downloads.component';
import { KlassaktProductsComponent } from './app-shell/products/products.component';
import { KlassaktPackagesComponent } from './app-shell/packages/packages.component';
import { KlassaktOrdersComponent } from './app-shell/orders/orders.component';


export const klassaktAppRoutes: Routes = [
  {
    path: '',
    component: KlassaktStaticPagesComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: KlassaktDashboardMainComponent,
    canActivate: [KlassaktPermissionGuard],
    data: {
      permission: '!'
    },
    children: [{
      path: '',
      component: KlassaktDashboardSectionsComponent,
      canActivate: [KlassaktPermissionGuard],
      outlet: 'dashboardRouter',
      data: {
        permission: '!'
      }
    },
      {
        path: 'gallery',
        component: KlassaktGalleryComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'gallery/:imageUrl',
        component: KlassaktGalleryComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'viewcart',
        component: KlassaktViewcartComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
       {
        path: 'viewcartpayment',
        component: KlassaktViewcartpaymentComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'downloads',
        component: KlassaktDownloadsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
      path: 'orders',
      component: KlassaktOrdersComponent,
      canActivate: [KlassaktPermissionGuard],
      outlet: 'dashboardRouter',
      data: {
        permission: '!'
      }
      },
      {
        path: 'orderSummary',
        component: KlassaktOrderSummaryComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'support',
        component: KlassaktSupportComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'products',
        component: KlassaktProductsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'packages',
        component: KlassaktPackagesComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'product',
        component: KlassaktProductDetailsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'package',
        component: KlassaktPackageDetailsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'account',
        component: KlassaktAccountComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'product/:productId',
        component: KlassaktProductDetailsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'package/:packageId',
        component: KlassaktPackageDetailsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'changePassword',
        component: KlassakChangePasswordComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'hearts',
        component: KlassaktHeartsComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'orderConfirmation',
        component: KlassaktOrderConfirmationComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
      {
        path: 'orderConfirmation/:rid',
        component: KlassaktOrderConfirmationComponent,
        canActivate: [KlassaktPermissionGuard],
        outlet: 'dashboardRouter',
        data: {
          permission: '!'
        }
      },
    ]
  },
  {
    path: 'pages/:pageName',
    component: KlassaktStaticPagesComponent,
    pathMatch: 'full'
  },
  
  //{
  //  path: '',
  //  pathMatch: 'full',
  //  component: KlassaktRedirectComponent,
  //  data: {
  //    redirectTo: [
  //      { permission: '?' },
  //      { target: 'pages' }
  //    ]
  //  }
  //},
  {
    path: '**',
    component: KlassaktNotFoundComponent
  }
];

//export const appRoutingProviders: any[] = [];

//export const routing = RouterModule.forRoot(appRoutes);
